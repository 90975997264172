import { FC } from 'react';

interface Props {
	setIf?: boolean,
	setWhen: boolean,
	className?: string,
}

export const Required: FC<Props> = ({setIf=true, setWhen, className}) => {
	return (
		setIf
			? setWhen 
				? <i className={`bi bi-check text-success small ${className}`} title="This required field has been entered." style={{position: 'relative', top: '-4px', width: '10px'}}/>
				: <i className={`bi bi-exclamation-lg text-warning fw-bold ms-n2 ${className}`} title="This is a required field." style={{position: 'relative', top: '-2px', left: '-2px', width: '10px'}}/>
			: null
	)
};
