import { Route, Routes } from "react-router-dom";
import { NavBar } from "./components/navBar/NavBar";
import { CustomToaster } from "./components/CustomToaster";
import { ScrollToTop } from "./components/ScrollToTop";
import { FrpWorkersDisplay } from "./pages/ferpa/FrpWorkersDisplay";
import { FrpWorkerAddOrUpdate } from "./pages/ferpa/FrpWorkerAddOrUpdate";
import { FrpInfoTypesDisplay } from "./pages/ferpa/FrpInfoTypesDisplay";
import { FrpInfoTypeAddOrUpdate } from "./pages/ferpa/FrpInfoTypeAddOrUpdate";
import { FrpRelationAddOrUpdate } from "./pages/ferpa/FrpRelationAddOrUpdate";
import { FrpRelationsDisplay } from "./pages/ferpa/FrpRelationsDisplay";
import { FrpPassphraseAddOrUpdate } from "./pages/ferpa/FrpPassphraseAddOrUpdate";
import { FrpDelegatesDisplay } from "./pages/ferpa/FrpDelegatesDisplay";
import { FrpDelegateAddOrUpdate } from "./pages/ferpa/FrpDelegateAddOrUpdate";
import { FrpSessionSearch } from "./pages/ferpa/FrpSessionSearch";
import { FrpSessionActive } from "./pages/ferpa/FrpSessionActive";
import { FrpDirectoryInfoAddOrUpdate } from "./pages/ferpa/FrpDirectoryInfoAddOrUpdate";
import { FrpPageVisitorsDisplay } from "./pages/ferpa/FrpPageVisitorsDisplay";
import { FrpSessionsDisplay } from "./pages/ferpa/FrpSessionsDisplay";
import { useIsFrpAdminQuery, useIsFrpWorkerQuery } from "./hooks/frpHooks";
import { FrpCard } from "./components/ferpa/FrpCard";
import { FrpFacilitatorSummaryList } from "./pages/ferpa/FrpFacilitatorSummaryList";


export const AppRoutes = () => {
  const isMobile = window.innerWidth < 768

  const isFrpAdminQuery = useIsFrpAdminQuery()
  const isFrpAdmin = isFrpAdminQuery.data
  const isFrpWorkerQuery = useIsFrpWorkerQuery()
  const isFrpWorker = isFrpWorkerQuery.data

  return (
    <>
      <div className="justify-content-center">
        <CustomToaster />
        <div className="d-flex flex-column nav-flex">
          <NavBar />
          <ScrollToTop />
          {isMobile && <div className="opacity-50 small ms-2">To return to the main menu, click on the logo above</div>}
          <div className="d-flex justify-content-between">
            {!isMobile && 
              <div className="d-md-inline d-sm-none mt-2 d-flex ms-2">
                <FrpCard />
              </div>
            }
            <div className="mx-auto mt-3 col-12 col-sm-9" style={{overflowY: 'auto', height: '90vh', marginBottom: '100px'}}>
              <Routes>
                <Route path="/" element={isMobile ? <FrpCard /> : isFrpAdmin || isFrpWorker ? <FrpSessionsDisplay /> : <FrpPassphraseAddOrUpdate />} />
                <Route path="/frp/workers" element={<FrpWorkersDisplay />} />
                <Route path="/frp/addWorker" element={<FrpWorkerAddOrUpdate />} />
                <Route path="/frp/addWorker/:frpWorkerId" element={<FrpWorkerAddOrUpdate />} />
                <Route path="/frp/infoTypeList" element={<FrpInfoTypesDisplay />} />
                <Route path="/frp/addInfoType" element={<FrpInfoTypeAddOrUpdate />} />
                <Route path="/frp/addInfoType/:frpInfoTypeId" element={<FrpInfoTypeAddOrUpdate />} />
                <Route path="/frp/relations" element={<FrpRelationsDisplay />} />
                <Route path="/frp/addRelation" element={<FrpRelationAddOrUpdate />} />
                <Route path="/frp/addRelation/:frpRelationId" element={<FrpRelationAddOrUpdate />} />
                <Route path="/frp/student/passphrase" element={<FrpPassphraseAddOrUpdate />} />
                <Route path="/frp/student/directoryInfo" element={<FrpDirectoryInfoAddOrUpdate />} />
                <Route path="/frp/session/active/:frpSessionId" element={<FrpSessionActive />} />
                <Route path="/frp/sessions" element={<FrpSessionsDisplay />} />
                <Route path="/frp/sessions/:frpWorkerUsername" element={<FrpSessionsDisplay />} />
                <Route path="/frp/session/add" element={<FrpSessionSearch />} />
                <Route path="/frp/delegates" element={<FrpDelegatesDisplay />} />
                <Route path="/frp/addDelegate" element={<FrpDelegateAddOrUpdate />} />
                <Route path="/frp/addDelegate/:frpDelegateId/:frpStudentId" element={<FrpDelegateAddOrUpdate />} />
                <Route path="/frp/page/visitors" element={<FrpPageVisitorsDisplay />} />
                <Route path="/frp/facilitator/summary" element={<FrpFacilitatorSummaryList />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
