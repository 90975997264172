import { useEffect, useState } from 'react'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate } from 'react-router-dom'
import { FrpFilterSessionSearch } from '../../components/ferpa/FrpFilterSessionSearch'
import { FrpNewSession, FrpSession, FrpStudent } from '../../models/frp'
import { useAddFrpSessionMutation, useAddSessionNoFrpStudentMutation, useExpireOldSessionsMutation, useUpdateSessionLastDateMutation } from '../../hooks/frpHooks'

export const FrpSessionSearch = () => {
    const navigate = useNavigate()
    const isMobile = window.innerWidth < 768
    
    const [filteredStudents, setFilteredStudents] = useState<FrpStudent[]>()
    const [searching, setSearching] = useState<boolean>()
    const [filterCount, setFilterCount] = useState<number>(0)
    const [talkingTo, setTalkingTo] = useState<string>('')

    const expireOldSessions = useExpireOldSessionsMutation();
    const updateSessionLastDate = useUpdateSessionLastDateMutation()
    const addNewSession = useAddFrpSessionMutation()
    const startNoFrpStudent = useAddSessionNoFrpStudentMutation()

    useEffect(() => {
        expireOldSessions.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createNewSession = (frpStudent: FrpStudent) => {
        const frpNewSession: FrpNewSession = {
            talkingTo,
            frpStudentId: frpStudent.frpStudentId,
            badgerid: frpStudent.badgerid || '',
            emailAddress: frpStudent.username || '',
            username: frpStudent.username && frpStudent.username.indexOf('@') > -1 ? frpStudent.username.substring(0, frpStudent.username.indexOf('@')) : frpStudent.username || '',
            firstName: frpStudent.firstName || '',
            lastName: frpStudent.lastName || '',
        }
        addNewSession.mutate({frpNewSession})
    } 

    const showButtonVersion = (student: FrpStudent) => {
        const activeSessionId = student.sessions && getActiveSession(student.sessions)
        console.log('student',student)
        if (activeSessionId) {
            return (
                <div className={`ms-4 btn ${student.passphrase ? 'btn-outline-success' : 'btn-outline-info'}`}
                        title="This student has an active session"
                        onClick={() => {
                            updateSessionLastDate.mutate({frpSessionId: activeSessionId})
                            navigate(`/frp/session/active/${activeSessionId || 0}`)
                        }}>
                    Active
                </div>
            )
        } else if (student.frpStudentId) {
            return (
            
                <div className={`ms-4 btn ${student.passphrase ? 'btn-outline-success' : 'btn-outline-bold'}`}
                        title="Start a new session for this student or a delegate" onClick={() => createNewSession(student)}>
                    <div>{student.passphrase ? 'Has Passphrase' : 'No Passphrase'}</div>
                </div>
            )
        } else  {
            return (
                <div className="ms-4 btn btn-outline-danger"
                        title="This student does not have a registered passphrase nor delegate" onClick={() => startNoFrpStudent.mutate({student})}>
                    <div>Hasn't Registered</div>
                </div>
            )
        }
    }

    const getActiveSession = (sessions: FrpSession[]) => {
        let activeSession = sessions?.filter(m => !m.isClosed)[0]
        if (activeSession) return activeSession.frpSessionId
    }

    const handleSetSearching = (value: boolean) => {
        setSearching(value)
        document.getElementById('filterAccordion')?.click()
    }

    return (
        <div className="mx-auto container" style={{marginBottom: '75px'}}>
            <h5 className="border bg-bold text-white rounded p-1">FERPA Search Student to Create New Session</h5>
            {isMobile && 
                <div>
                    <div className="accordion" id="filterAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#filterOptions"
                                aria-expanded="false"
                                aria-controls="filterOptions"
                            >
                                Filter Options
                                <span className="badge bg-warning rounded-pill mt-2 text-black ms-1" title="Number of filters set">
                                    {filterCount > 0 ? filterCount : ''}
                                </span>
                            </button>
                            </h2>
                            <div id="filterOptions" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#filterAccordion" >
                                <div className="accordion-body">
                                    <FrpFilterSessionSearch setFilteredStudents={setFilteredStudents} 
                                                            setSearching={handleSetSearching}
                                                            setFilterCount={setFilterCount} 
                                                            talkingTo={talkingTo} 
                                                            setTalkingTo={setTalkingTo} />
                                </div>
                            </div>
                            {searching && <div className="fst-italic text-warning">Please wait while we locate records that match your criteria</div>}
                        </div>
                    </div>                    
                    <hr/>
                    {filteredStudents?.map((m: FrpStudent, index: number) => 
                        <div key={index}>
                            <TextDisplay label="Student" text={`${m.firstName} ${m.middleName ? m.middleName + ' ' : ''}${m.lastName} ${m.isOffDirectoryInfo ? 'CONFIDENTIAL' : ''}`} />
                            <div className="d-flex  mt-3">
                                {showButtonVersion(m)}
                            </div>
                            <TextDisplay label="Sessions" text='' jsx={<div onClick={() => navigate(`/frp/sessions/student/${m.frpStudentId}`)}>{(m.sessions && m.sessions.length) || 0}</div>} />
                            <TextDisplay label="Badgerid" text={m.badgerid || ''}/>
                            <TextDisplay label="Username" text={m.username || ''}/>
                            <hr/>
                        </div>
                    )}
                </div>
            }
            {!isMobile && 
                <div className="mx-auto wide-container w-100" style={{width: '100%'}}>
                    <FrpFilterSessionSearch setFilteredStudents={setFilteredStudents} 
                                            setSearching={setSearching}
                                            setFilterCount={setFilterCount} 
                                            talkingTo={talkingTo} 
                                            setTalkingTo={setTalkingTo} />
                    <div className="table-responsive w-100">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="me-2 pe-2">Student</th>
                                    <th className="me-2 pe-2">Sessions</th>
                                    <th className="me-2 pe-2">Badger ID</th>
                                    <th className="me-2 pe-2">Username</th>
                                </tr>
                            </thead>
                            <tbody>
                            {searching && <tr><td colSpan={10} className="fst-italic text-warning">Please wait while we locate records that match your criteria</td></tr>}
                            {filteredStudents?.map((m: FrpStudent, index: number) => 
                                <tr key={index}>
                                    <td>
                                        {showButtonVersion(m)}
                                    </td>
                                    <td>
                                        <div className="d-flex">
                                            <div>{`${m.firstName} ${m.middleName ? m.middleName + ' ' : ''}${m.lastName} `}</div>
                                            <div className="text-warning ms-2">{m.isOffDirectoryInfo ? 'CONFIDENTIAL' : ''}</div>
                                        </div>
                                    </td>
                                    <td><div onClick={() => navigate(`/frp/sessions/student/${m.frpStudentId}`)}>{(m.sessions && m.sessions.length) || 0}</div></td>
                                    <td>{m.badgerid}</td>
                                    <td>{m.username}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {!searching && !(filteredStudents && filteredStudents.length > 0) &&
                <h5>Enter search criteria above</h5>
            }
        </div>
    )
}
