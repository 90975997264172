import { FC, useEffect, useState } from 'react'
import { FrpVisitor } from '../../models/frp'
import doSort from '../../utils/sort'

interface IdLabelOption {
    id: string,
    label: string
}

interface Props {
    setFilterCount: (c: number) => void,
    setFilteredVisitors: (c: FrpVisitor[]) => void,
    visitors?: FrpVisitor[]
}

export const FrpFilterPageVisitorsDisplay: FC<Props> = ({setFilterCount, setFilteredVisitors, visitors}) => {
    const isMobile = window.innerWidth < 768
    
    const [searchDateFrom, setSearchDateFrom] = useState<string>()
    const [searchDateTo, setSearchDateTo] = useState<string>()
    const [searchVisitorName, setSearchVisitorName] = useState<string>('')
    const [searchPage, setSearchPage] = useState<string>('')
    const [searchDescription, setSearchDescription] = useState<string>('')

    let pageOptions = visitors?.reduce((acc: IdLabelOption[], m: FrpVisitor) => {
        const alreadyExists = acc?.filter(f => f.id === m.page)[0]
        if (!alreadyExists) {
            let option =  { id: m.page, label: m.page}
            acc = acc && acc.length > 0 ? acc.concat(option) : [option]
        }
        return acc
    }, [])

    pageOptions = doSort(pageOptions, {sortField: 'label', isAsc: true, isNumber: false})


    useEffect(() => {
        let newCount = 0
        let newFiltered = visitors ? [...visitors] : []
        if (searchDateFrom) {
            newCount++; 
            newFiltered = newFiltered?.filter(m => {
                const dateFrom = new Date(m.entryDate)
                const searchDate = new Date(searchDateFrom);
                return dateFrom >= searchDate
            })
        }
        if (searchDateTo) {
            newCount++; 
            newFiltered = newFiltered?.filter(m => {
                const dateFrom = new Date(m.entryDate)
                const searchDate = new Date(searchDateTo);
                return dateFrom <= searchDate
            })
        }
        if (searchVisitorName) {
            newCount++; 
            const arraySearch = searchVisitorName.split(' ')
            for(let i = 0; i < arraySearch.length; i++) {
                newFiltered = newFiltered?.filter(m => m.userUsername.toLowerCase().indexOf(arraySearch[i].toLowerCase()) > -1);
            }
        }
        if (searchPage) {newCount++; newFiltered = newFiltered?.filter(m => m.page.toLowerCase() === searchPage.toLowerCase());}
        if (searchDescription) {newCount++; newFiltered = newFiltered?.filter(m => m.description.toLowerCase().indexOf(searchDescription) > -1);}
        setFilteredVisitors(newFiltered)
        setFilterCount(newCount)
    }, [searchDateFrom, searchDateTo,  searchVisitorName, searchPage, searchDescription, visitors, setFilteredVisitors, setFilterCount])

    const clearFilters = () => {
        setSearchDateFrom('')
        setSearchDateTo('')
        setSearchVisitorName('')
        setSearchPage('')
        setSearchDescription('')
    }

    return (
        <div className="bg-secondary-subtle pt-2 rounded">
            <div className="d-flex flex-wrap ms-3 text-left">
                <div>
                    <div className="form-check my-auto mt-n2">
                        <label className="form-check-label small my-auto" htmlFor="futureDates">
                            Date From
                        </label>
                        <input 
                            type="date"
                            className="form-control form-control-sm"
                            style={{width: 'auto'}}
                            name="searchDateFrom"
                            value={searchDateFrom?.toString().split('T')[0]}
                            onChange={(event) => setSearchDateFrom(event?.target.value)}
                        />
                    </div>
                    <div className="form-check me-3 my-auto mb-2">
                        <label className="form-check-label small" htmlFor="futureDates">
                            Date To
                        </label>
                        <input 
                            type="date"
                            style={{width: 'auto'}}
                            className="form-control form-control-sm"
                            name="searchDateTo"
                            value={searchDateTo?.toString().split('T')[0]}
                            onChange={(event) => setSearchDateTo(event?.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-3 me-1 my-auto" style={{width: `${isMobile ? '120px' : 'auto'}`}}>
                    <label htmlFor="nameSearch" className="form-label small my-auto me-2 pe-2">User's Name</label>
                    <input 
                        type="text"
                        style={{width: '125px'}}
                        className="form-control form-control-sm"
                        id="searchVisitorName"
                        value={searchVisitorName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchVisitorName(event.target.value)}
                    />
                </div>
                <div className="mb-3 me-1 my-auto">
                    <label htmlFor="searchPage" className="form-label small my-auto me-2 pe-2">Page</label>
                    <select
                        id="searchPage"
                        name="searchPage"
                        style={{width: 'auto'}} 
                        className="form-select form-select-sm"
                        value={searchPage}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setSearchPage(event.target.value)}
                    >
                    <option key={0} value={0}>- -</option>
                    {pageOptions?.map((m: IdLabelOption, i: number) => 
                        <option key={i} value={m.id}>
                        {m.label}
                        </option>
                    )}            
                    </select>
                </div>
                <div className="mb-3 me-1 my-auto" style={{width: `${isMobile ? '120px' : 'auto'}`}}>
                    <label htmlFor="badgeridSearch" className="form-label small my-auto me-2 pe-2">Description</label>
                    <input 
                        type="text"
                        style={{width: '125px'}}
                        className="form-control form-control-sm"
                        id="searchDescription"
                        value={searchDescription}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchDescription(event.target.value)}
                    />
                </div>
                <div>
                    <button className="btn btn-text text-bold ms-4" onClick={clearFilters}>clear filters</button>
                </div>
            </div>
        </div>
    )
}

