import { useEffect, useState } from 'react'
import { FormatShortDayOfWeekMonthDayTime } from '../../utils/dateConverter'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate, useParams } from 'react-router-dom'
import { useIsFrpAdminQuery, useIsFrpWorkerQuery, useSearchFrpSessionsMutation } from '../../hooks/frpHooks'
import { FrpSession, FrpSessionSearch } from '../../models/frp'
import { FrpFilterSessionsDisplay } from '../../components/ferpa/FrpFilterSessionsDisplay'
import { Pagination } from '../../components/ui/Pagination'

export const FrpSessionsDisplay = () => {
    const navigate = useNavigate()
    const params = useParams()
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    const frpWorkerUsername = params.frpWorkerUsername ? params.frpWorkerUsername : ''
    const isMobile = window.innerWidth < 1376
    const queryTake = 12

    const [filteredSessions, setFilteredSessions] = useState<FrpSession[]>()
    const [filterCount, setFilterCount] = useState<number>(0)
    const [searching, setSearching] = useState<boolean>();
    const [sessionSearch, setSessionSearch] = useState<FrpSessionSearch>({
      pagePointer: 1,
      queryTake,
      dateFromSearch: todayDate.toISOString().substring(0,10),
    });

    const searchFrpSessions = useSearchFrpSessionsMutation();
    const isFrpAdminQuery = useIsFrpAdminQuery()
    const isFrpAdmin = isFrpAdminQuery.data
    const isFrpWorkerQuery = useIsFrpWorkerQuery()
    const isFrpWorker = isFrpWorkerQuery.data

    useEffect(() => {
      searchFrpSessions.mutate({
        sessionSearch,
        setFilteredSessions,
        setSearching,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionSearch]);

    if (!isFrpAdmin && !isFrpWorker) <h3>This page is reserved for FERPA administrators and facilitators</h3>;

    const sendToSession = (frpSessionId: number) => {
        if (frpSessionId && frpSessionId > 0) {
            navigate(`/frp/session/active/${frpSessionId}`)
        }
    }

    const showPassOrFail = (session: FrpSession) => {
        if (session.passOrFail === 'Pass') {
            return <div className="text-success my-auto mt-1">Passed</div>
        } else if (session.passOrFail === 'Fail') {
            return <div className="text-danger my-auto mt-1">Failed</div>
        } else {
            return <div className="text-info my-auto mt-1">Unverified</div>
        }
    }

    return (
      <div
        style={{ minWidth: isMobile ? "auto" : "1000px", marginBottom: "75px" }}
        className="mx-auto"
      >
        <h5 className="border bg-bold text-white rounded p-1">
          Ferpa Sessions
        </h5>
        {isMobile && (
          <div>
            <div className="accordion" id="filterAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#filterOptions"
                    aria-expanded="false"
                    aria-controls="filterOptions"
                  >
                    Filter Options
                    <span
                      className="badge bg-warning rounded-pill mt-2 text-black ms-1"
                      title="Number of filters set"
                    >
                      {filterCount > 0 ? filterCount : ""}
                    </span>
                  </button>
                </h2>
                <div
                  id="filterOptions"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#filterAccordion"
                >
                  <div className="accordion-body">
                    <FrpFilterSessionsDisplay
                      setSessionSearch={setSessionSearch}
                      setFilterCount={setFilterCount}
                      frpWorkerUsername={frpWorkerUsername}
                      setSearching={setSearching}
                      pagePointer={sessionSearch.pagePointer}
                      queryTake={queryTake}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isFrpWorker && (
              <div
                className="btn btn-outline-bold my-3"
                onClick={() => navigate("/frp/session/add")}
              >
                Add New Session
              </div>
            )}
            {filteredSessions && filteredSessions.length > 0 && (
              <div className="d-flex w-100 justify-content-center">
                <Pagination
                  totalCount={filteredSessions[0].totalCount || 0}
                  sessionSearch={sessionSearch}
                  setSessionSearch={setSessionSearch}
                />
              </div>
            )}
            {searching && (
              <div className="fst-italic text-warning">
                Please wait while we locate records that match your criteria
              </div>
            )}
            <hr />
            {filteredSessions?.map((m: FrpSession, index: number) => (
              <div key={index}>
                <TextDisplay
                  label="Active"
                  text=""
                  jsx={
                    m.isClosed ? (
                      <div>Closed</div>
                    ) : (
                      <div
                        className={`btn btn-text btn-link ${
                          m.studentPassphrase ? "text-bold" : "text-info"
                        }`}
                        onClick={() => sendToSession(m.frpSessionId)}
                      >
                        Active
                      </div>
                    )
                  }
                />
                <TextDisplay label="Student" text={`${m.studentFirstName} ${m.studentLastName}`} />
                <TextDisplay label="Badger ID" text={`${m.studentBadgerid}`} />
                <TextDisplay label="Delegate" text={`${m.delegateFirstName || ""} ${ m.delegateLastName || "" }`} />
                <TextDisplay label="Verified" text="" jsx={showPassOrFail(m)} />
                <TextDisplay label="Comments" text="" jsx={m.comments && m.comments?.length > 0 ? String(m.comments?.length) : <div className="fst-italic">none</div>} />
                <TextDisplay label="Last updated" text={FormatShortDayOfWeekMonthDayTime(m.lastUpdated)} />
                <TextDisplay label="Facilitator" text={`${m.workerUsername}`} />
                <hr />
              </div>
            ))}
          </div>
        )}
        {!isMobile && (
          <div
            className="mx-auto wide-container w-100"
            style={{ width: "100%" }}
          >
            <FrpFilterSessionsDisplay
              setSessionSearch={setSessionSearch}
              setFilterCount={setFilterCount}
              frpWorkerUsername={frpWorkerUsername}
              setSearching={setSearching}
              pagePointer={sessionSearch.pagePointer}
              queryTake={queryTake}
            />
            {isFrpWorker && (
              <div
                className="btn btn-outline-bold my-3"
                onClick={() => navigate("/frp/session/add")}
              >
                Add New Session
              </div>
            )}
            {filteredSessions && filteredSessions.length > 0 && (
              <div className="d-flex w-100 justify-content-center">
                <Pagination
                  totalCount={filteredSessions[0].totalCount || 0}
                  sessionSearch={sessionSearch}
                  setSessionSearch={setSessionSearch}
                />
              </div>
            )}
            {searching && (
              <div className="fst-italic text-warning">
                Please wait while we locate records that match your criteria
              </div>
            )}
            <div className="table-responsive w-100">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="me-2 pe-2">Active</th>
                    <th className="me-2 pe-2">Student</th>
                    <th className="me-2 pe-2">Badger ID</th>
                    <th className="me-2 pe-2">Delegate</th>
                    <th className="me-2 pe-2">Verified</th>
                    <th className="me-2 pe-2">Comments</th>
                    <th className="me-2 pe-2">Last updated</th>
                    <th className="me-2 pe-2">Facilitator</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSessions?.map((m: FrpSession, index: number) => (
                    <tr key={index}>
                      <td className='align-middle'>
                        {m.isClosed ? (
                          <div
                            className="btn btn-text btn-link text-secondary  my-auto"
                            onClick={() =>
                              navigate(`/frp/session/active/${m.frpSessionId}`)
                            }
                          >
                            Closed
                          </div>
                        ) : (
                          <div
                            className={`btn btn-text btn-link my-auto ${
                              m.studentPassphrase ? "text-bold" : "text-info"
                            }`}
                            onClick={() =>
                              navigate(`/frp/session/active/${m.frpSessionId}`)
                            }
                          >
                            Active
                          </div>
                        )}
                      </td>
                      <td className='align-middle'>
                        <div className="my-auto">{`${m.studentFirstName} ${m.studentLastName}`}</div>
                      </td>
                      <td className='align-middle'>
                        <div className="my-auto">{`${m.studentBadgerid}`}</div>
                      </td>
                      <td className='align-middle'>
                        <div className="my-auto">{`${m.delegateFirstName || ""} ${
                          m.delegateLastName || ""
                        }`}</div>
                      </td>
                      <td className='align-middle'>
                        <div className="my-auto">{showPassOrFail(m)}</div>
                      </td>
                      <td className='align-middle'>
                        <div className="my-auto">
                          {m.comments && m.comments?.length > 0 ? m.comments?.length : ''}
                        </div>
                      </td>
                      <td className='align-middle'>
                        <div className="my-auto">
                          {FormatShortDayOfWeekMonthDayTime(m.lastUpdated)}
                        </div>
                      </td>
                      <td className='align-middle'>
                        <div className="my-auto">{`${m.workerUsername}`}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!(filteredSessions && filteredSessions.length > 0) && (
          <h5>
            There are not any FERPA sessions found according to the criteria
          </h5>
        )}
        {filteredSessions && filteredSessions.length > 0 && (
          <div className="d-flex w-100 justify-content-center">
            <Pagination
              totalCount={filteredSessions[0].totalCount || 0}
              sessionSearch={sessionSearch}
              setSessionSearch={setSessionSearch}
            />
          </div>
        )}
      </div>
    );
}

