import { Spinner } from '../../components/ui/Spinner'
import toast from 'react-hot-toast'
import { ConfirmationToast } from '../../components/ConfirmationToast'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate } from 'react-router-dom'
import { FrpRelation } from '../../models/frp'
import { useDeleteFrpRelationMutation, useGetFrpRelationsQuery } from '../../hooks/frpHooks'
import { createInfoToastAuto } from '../../services/queryClient'

export const FrpRelationsDisplay = () => {
    const navigate = useNavigate()
    const isMobile = window.innerWidth < 768

    const deleteFrpRelation = useDeleteFrpRelationMutation()
    const getFrpRelationsQuery = useGetFrpRelationsQuery()
    const frpRelations = getFrpRelationsQuery.data

    if (getFrpRelationsQuery.isLoading)
        return <Spinner />;
    if (getFrpRelationsQuery.isError)
        return <h3>Error getting Relationships</h3>;

    const sendToEditRelation = (frpRelationId: number) => {
        if (frpRelationId > 0) navigate(`/frp/addRelation/${frpRelationId}`) 
    }

    const deleteConfirm = (frpRelationId: number) => {
        toast((t) => (
            <ConfirmationToast
                toastId={t.id}
                message={`Are you sure you want to delete this delegate relationship?`}
                confirmHandler={() => {
                    deleteFrpRelation.mutate({frpRelationId})
                    createInfoToastAuto(`The delegate relationship has been deleted.`)
                    toast.dismiss(t.id);
                }} />
        ), { duration: Infinity })
    }

    return (
        <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
            <div style={{maxWidth: '1200px', }} className="justify-content-center">
                <h5 className="border bg-bold text-white rounded p-1">FERPA Delegate Relationships</h5>
                {frpRelations && frpRelations.length > 10 &&
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addRelation")}>
                        Add a Relationship
                    </button>
                }
                <div>
                    {isMobile && 
                        <div>
                            <hr/>
                            {frpRelations && frpRelations.length > 0 && frpRelations.map((m: FrpRelation, index: number) => 
                                <div key={index}>
                                    <TextDisplay label="Name" text={`${m.name}`} />
                                    <div className="d-flex flex-nowrap mt-4">
                                        <div className="ms-4 btn btn-outline-bold" title="Edit this relation" onClick={() => sendToEditRelation(m.frpRelationId || 0)}>
                                            <i className="bi bi-pencil d-block"></i>
                                        </div>
                                        <div className="ms-4 btn btn-outline-danger" title="Delete this relation" onClick={() => deleteConfirm(m.frpRelationId || 0)}>
                                            <i className="bi bi-trash d-block"></i>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            )}
                        </div>
                    }
                    {!isMobile && 
                        <div className="table-responsive w-100">
                            <table className="table table-striped w-sm-100 w-md-80 w-lg-50">
                                <thead>
                                    <tr>
                                        <th className="me-3 pe-5">Name</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {frpRelations && frpRelations.length > 0 && frpRelations.map((m: FrpRelation, index: number) => 
                                    <tr key={index}>
                                        <td>{m.name}</td>
                                        <td>
                                            <div className="d-flex flex-nowrap">
                                                <div className="btn btn-outline-bold me-2" onClick={() => sendToEditRelation(m.frpRelationId || 0)}>
                                                    <i className="bi bi-pencil"></i>
                                                </div>
                                                <div className="btn btn-outline-danger" onClick={() => deleteConfirm(m.frpRelationId || 0)}>
                                                    <i className="bi bi-trash"></i>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    }
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addRelation")}>
                        Add a Relationship
                    </button>
                    {!(frpRelations && frpRelations.length > 0) &&
                        <h5>There are not any frpRelations</h5>
                    }
                </div>
            </div>
        </div>
    )
}

