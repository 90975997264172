import { AxiosRequestHeaders } from "axios";
import { useAuth } from "react-oidc-context";
import { User } from "../models/User";
import { useQuery } from "@tanstack/react-query";
import { permissionsService } from "../services/authService";
import { userService } from "../services/userServices";

export enum Groups {
  All,
  Student,
  Admin,
  Employee,
  Faculty,
}

export const userKeys = {
  contactInfoKey: ["contactInfoKey"] as const,
  isStudentKey: (badgerid: string) => ["isStudent", badgerid] as const,
  isEmployeeKey: (badgerid: string) => ["isEmployee", badgerid] as const,
  isSupervisorKey: (badgerid: string) => ["isSupervisorKey", badgerid] as const,
  
  isTeacherKey: (badgerid: string) => ["isTeacher", badgerid] as const,
  permissionsKey: (badgerid: string) => ["permissions", badgerid] as const,
};

export const useUser = () => {
  const auth = useAuth();
  if (!auth.user) {
    throw new Error("logging in");
  }
  // auth.user.profile.badgerid = '00235039'
  // auth.user.profile.preferred_username = 'bill.schuetz'
  return auth.user.profile as User;
};

export const useUserHasGroup = (groupName: string) => {
  const user = useUser();
  return user.groups.includes(groupName);
};

export const useAccessTokenHeader = (): AxiosRequestHeaders => {
  const auth = useAuth();
  if (!auth.user) {
    // auth.signinRedirect();
    throw new Error("logging in");
  }
  const token = auth.user.access_token;
  return { Authorization: `Bearer ${token}` };
};

export const useGetIsEmployeeQuery = () => {
  const authHeader = useAccessTokenHeader();
  const user = useUser();
  return useQuery(userKeys.isEmployeeKey(user.badgerid), async () => {
    return await permissionsService.getIsEmployee(authHeader);
  });
};

export const useGetIsSupervisorQuery = () => {
  const authHeader = useAccessTokenHeader();
  const user = useUser();
  return useQuery(userKeys.isSupervisorKey(user.badgerid), async () => {
    return await permissionsService.getIsSupervisor(authHeader);
  });
};

export const useGetIsTeacherQuery = () => {
  const authHeader = useAccessTokenHeader();
  const user = useUser();
  return useQuery(userKeys.isTeacherKey(user.badgerid), async () => {
    return await permissionsService.getIsTeacher(authHeader);
  });
};

export const useGetIsStudentQuery = () => {
  const authHeader = useAccessTokenHeader();
  const user = useUser();
  return useQuery(userKeys.isStudentKey(user.badgerid), async () => {
    return await permissionsService.getIsStudent(authHeader);
  });
};

export const useGetPermissionQuery = () => {
  const authHeader = useAccessTokenHeader();
  const user = useUser();

  return useQuery(userKeys.permissionsKey(user.badgerid), async () => { 
    return await permissionsService.getPermissions(authHeader);
  })
}

export const useGetContactInfoQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: userKeys.contactInfoKey,
    queryFn: async () => {
      return await userService.getContactInfo(authHeader);
    },
  });
};
