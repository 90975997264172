import { useNavigate } from 'react-router-dom';
import { useIsFrpAdminQuery, useIsFrpWorkerQuery } from '../../hooks/frpHooks';

export const FrpAdminLanding = () => {
  const navigate = useNavigate()

  const isFrpAdminQuery = useIsFrpAdminQuery()
  const isFrpAdmin = isFrpAdminQuery.data
  const isFrpWorkerQuery = useIsFrpWorkerQuery()
  const isFrpWorker = isFrpWorkerQuery.data

  if (!isFrpAdmin) return <h3>This page is reserved for Ferpa Administrators</h3>

  const hasPathName = (partial: string) => {
    return window.location.pathname.indexOf('/' + partial) > -1
  }

  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-start mt-n3">
        <div className="col-auto text-left">
          <div className="ps-1 pt-4 list-group">
            {isFrpWorker && 
              <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('session/add') ? "active text-white" : ""}`} 
                    onClick={() => navigate(`/frp/session/add`)}>
                <i className="bi bi-node-plus pe-2"></i>
                Add New Session
              </div>
            }
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('sessions') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/sessions`)}>
              <i className="bi bi-list-check pe-2"></i>
              Session List
            </div>
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('frp/facilitator/summary') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/facilitator/summary`)}>
              <i className="bi bi-list-columns pe-2"></i>
              Facilitator Summary
            </div>
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('frp/page/visitors') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/page/visitors`)}>
              <i className="bi bi-hourglass-bottom pe-2"></i>
              Visitor Page History
            </div>
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('workers') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/workers`)}>
              <i className="bi bi-person-gear pe-2"></i>
              Facilitators
            </div>
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('relations') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/relations`)}>
              <i className="bi bi-people pe-2"></i>
              Relationship List
            </div>
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('infoTypeList') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/infoTypeList`)}>
              <i className="bi bi-info-square pe-2"></i>
              Information Type List
            </div>
          </div>
        </div>
      </div>
    </div>    
	)
}

