import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast"

export const CustomToaster = () => {
  const defaultTheme = localStorage.getItem("theme") ?? "light"
  const [theme, setTheme] = useState(defaultTheme)
  const backgroundColor = theme === "light" ? "white" : "dark"
  const textColor = theme === "light" ? "dark" : "white"

  useEffect(() => {
    const updateTheme = () => {
      setTheme(localStorage.getItem("theme") ?? "light");
    }
    window.addEventListener('theme', updateTheme);

    return () => {
      window.removeEventListener('theme', updateTheme)
    }
  }, [])

  return (
    <Toaster toastOptions={{
      className: "bg-" + backgroundColor + " text-" + textColor
    }} />
  )
}
