export function splitUsernameFirstLast(username: string) {
  let firstName = username.substring(0, username.indexOf('.'))
  firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
  let lastName = username.substring(username.indexOf('.')+1)
  lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
  return firstName + ' ' + lastName
}

export function splitUsernameFirstOnly(username: string) {
  let firstName = username.substring(0, username.indexOf('.'))
  firstName = firstName.replace(/\d/g, '');
  firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
  return firstName
}

export function splitUsernameLastOnly(username: string) {
  let lastName = username.substring(username.indexOf('.') + 1);
  // Remove any digits from the last name
  lastName = lastName.replace(/\d/g, '');
  lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
  return lastName;
}

export function splitLastFirstName(lastFirstName: string) {
  let arrayName = lastFirstName.split(' ')
  const lastName = arrayName[0].replace(',', '')
  const firstName = arrayName[1]
  return {firstName, lastName}
}

export function getBoolean(value: string | boolean | undefined | null) {
  if (value === 'undefined' || value === 'null' || value === '' || value === ' ' || value === 'false') return false
  return !!value
}

export function getIsFaculty(value: string | boolean | undefined | null) {
  return value === 'isFaculty' ? 'isFaculty' : ''
}
