import { Spinner } from '../../components/ui/Spinner'
import toast from 'react-hot-toast'
import { ConfirmationToast } from '../../components/ConfirmationToast'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate } from 'react-router-dom'
import { FrpInfoType } from '../../models/frp'
import { useDeleteFrpInfoTypeMutation, useGetFrpInfoTypesQuery } from '../../hooks/frpHooks'
import { createInfoToastAuto } from '../../services/queryClient'

export const FrpInfoTypesDisplay = () => {
    const navigate = useNavigate()
    const isMobile = window.innerWidth < 768

    const deleteFrpInfoType = useDeleteFrpInfoTypeMutation()
    const getFrpInfoTypesQuery = useGetFrpInfoTypesQuery()
    const frpInfoTypes = getFrpInfoTypesQuery.data

    if (getFrpInfoTypesQuery.isLoading)
        return <Spinner />;
    if (getFrpInfoTypesQuery.isError)
        return <h3>Error getting Information Types</h3>;

    const sendToEditInfoType = (frpInfoTypeId: number) => {
        if (frpInfoTypeId > 0) navigate(`/frp/addInfoType/${frpInfoTypeId}`) 
    }

    const deleteConfirm = (frpInfoTypeId: number) => {
        toast((t) => (
            <ConfirmationToast
                toastId={t.id}
                message={`Are you sure you want to delete this information type?`}
                confirmHandler={() => {
                    deleteFrpInfoType.mutate({frpInfoTypeId})
                    createInfoToastAuto(`The information type has been deleted.`)
                    toast.dismiss(t.id);
                }} />
        ), { duration: Infinity })
    }

    return (
        <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
            <div style={{maxWidth: '1200px', }} className="justify-content-center">
                <h5 className="border bg-bold text-white rounded p-1">FERPA Facilitators</h5>
                {frpInfoTypes && frpInfoTypes.length > 10 &&
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addInfoType")}>
                        Add an Information Type
                    </button>
                }
                <div className="text-justify mb-2">
                    <i className="bi bi-exclamation-triangle text-warning me-2"></i>
                    Be careful that you do not delete a record believing that you can simply recreate it. That action will replace its underlying ID that is tied to delegates' access.
                </div>
                <div>
                    {isMobile && 
                        <div>
                            <hr/>
                            {frpInfoTypes && frpInfoTypes.length > 0 && frpInfoTypes.map((m: FrpInfoType, index: number) => 
                                <div key={index}>
                                    <TextDisplay label="Short" text={`${m.short}`} />
                                    <TextDisplay label="Title" text={`${m.title}`} />
                                    <TextDisplay label="Description" text={m.description} />
                                    <TextDisplay label="Sequence" text={m.sequence} />
                                    <div className="d-flex flex-nowrap mt-4">
                                        <div className="ms-4 btn btn-outline-bold" title="Edit this information type" onClick={() => sendToEditInfoType(m.frpInfoTypeId || 0)}>
                                            <i className="bi bi-pencil d-block"></i>
                                        </div>
                                        <div className="ms-4 btn btn-outline-danger" title="Delete this information type" onClick={() => deleteConfirm(m.frpInfoTypeId || 0)}>
                                            <i className="bi bi-trash d-block"></i>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            )}
                        </div>
                    }
                    {!isMobile && 
                        <div className="table-responsive w-100">
                            <table className="table table-striped w-sm-100 w-md-80 w-lg-50">
                                <thead>
                                    <tr>
                                        <th className="me-3 pe-5">Short</th>
                                        <th className="me-3 pe-5">Title</th>
                                        <th className="me-3 pe-5">Description</th>
                                        <th className="me-3 pe-5">Sequence</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {frpInfoTypes && frpInfoTypes.length > 0 && frpInfoTypes.map((m: FrpInfoType, index: number) => 
                                    <tr key={index}>
                                        <td>{m.short}</td>
                                        <td>{m.title}</td>
                                        <td>{m.description}</td>
                                        <td>{m.sequence}</td>
                                        <td>
                                            <div className="d-flex flex-nowrap">
                                                <div className="btn btn-outline-bold me-2" onClick={() => sendToEditInfoType(m.frpInfoTypeId || 0)}>
                                                    <i className="bi bi-pencil"></i>
                                                </div>
                                                <div className="btn btn-outline-danger" onClick={() => deleteConfirm(m.frpInfoTypeId || 0)}>
                                                    <i className="bi bi-trash"></i>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    }
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addInfoType")}>
                        Add an Information Type
                    </button>
                    {!(frpInfoTypes && frpInfoTypes.length > 0) &&
                        <h5>There are not any frpInfoTypes</h5>
                    }
                </div>
            </div>
        </div>
    )
}

