import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { useUser } from "../hooks/userHooks";
import { toast } from "react-hot-toast";
import { axiosClient } from "../utils/axiosClient";
import { Permissions } from "../models/Permissions";

export const useIsIT = () => {
  const user = useUser();
  return (
    user.groups &&
    (user.groups.includes("IT-InternGRP") || user.groups.includes("ITgroup"))
  );
};

export const useIsAnyAdmin = () => {
  const user = useUser();
  return (
    user.groups &&
    (user.groups.includes("IT-InternGRP") ||
      user.groups.includes("ITgroup") ||
      user.groups.includes("PortalAnnouncementAdmin")||
      user.groups.includes("PortalLinksAdmin"))
  );
};
export const useIsAnouncementsAdmin = () => {
  const user = useUser();
  return (
    user.groups &&
    (user.groups.includes("IT-InternGRP") ||
      user.groups.includes("ITgroup") ||
      user.groups.includes("PortalAnnouncementAdmin"))
  );
};

export const useIsClubsAdmin = () => {
  const user = useUser();
  return user.groups && user.groups.includes("PortalClubAdmin");
};

export const useIsLinksAdmin = () => {
  const user = useUser();
  return (
    user.groups &&
    (user.groups.includes("IT-InternGRP") ||
      user.groups.includes("ITgroup") ||
      user.groups.includes("PortalLinksAdmin"))
  );
}

export const permissionsService = {
  async getIsEmployee(authHeader: AxiosRequestHeaders): Promise<boolean> {
    const url = `/api/permissions/isEmployee`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    if (response.status !== 200) toast.error("Error getting employee status");
    return response.data;
  },
  async getIsSupervisor(authHeader: AxiosRequestHeaders): Promise<boolean> {
    const url = `/api/permissions/isSupervisor`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    if (response.status !== 200) toast.error("Error getting employee status");
    return response.data;
  },
  async getIsStudent(authHeader: AxiosRequestHeaders): Promise<boolean> {
    const url = `/api/permissions/isStudent`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    if (response.status !== 200) toast.error("Error getting student status");
    return response.data;
  },
  async getIsTeacher(authHeader: AxiosRequestHeaders): Promise<boolean> {
    const url = `/api/permissions/isTeacher`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    if (response.status !== 200) toast.error("Error getting teacher status");
    return response.data;
  },
  async getPermissions(authHeader: AxiosRequestHeaders): Promise<Permissions> {
    const url = `/api/permissions`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    if (response.status !== 200) toast.error("Error getting permissions 999");
    return response.data;
  },
};

