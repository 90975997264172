import { useEffect, useState } from 'react'
import { createInfoToastAuto } from '../../services/queryClient';
import { Spinner } from '../../components/ui/Spinner';
import { FrpStudent } from '../../models/frp';
import { useAddOrUpdateFrpDirectoryInfoMutation, useGetFrpStudentSelfQuery } from '../../hooks/frpHooks';
import toast from 'react-hot-toast';
import { ConfirmationToast } from '../../components/ConfirmationToast';

export const FrpDirectoryInfoAddOrUpdate = () => {
  const addOrUpdateDirectoryInfo = useAddOrUpdateFrpDirectoryInfoMutation()
  const studentQuery = useGetFrpStudentSelfQuery()
  const student = studentQuery.data

  const [formData, setFormData] = useState<FrpStudent>({
    frpStudentId: 0,
    isOffDirectoryInfo: false,
  });

  const clearRelation = () => {
    setFormData({
      frpStudentId: 0,
      isOffDirectoryInfo: false,
    }) 
  }

  useEffect(() => {
    if (student) {
      setFormData({
        frpStudentId: student.frpStudentId,
        isOffDirectoryInfo: student.isOffDirectoryInfo,
      })      
    }
    return () => clearRelation()
  }, [student])

  if (studentQuery.isLoading)
    return <Spinner />
  if (studentQuery.isError)
    return <h3>Error getting the FERPA student directory info page</h3>;

  const addOrUpdate = (message:string) =>  {
    toast((t) => (
        <ConfirmationToast
            toastId={t.id}
            message={message}
            confirmHandler={() => {
              let newData = {...formData}
              newData.isOffDirectoryInfo = !newData.isOffDirectoryInfo
              setFormData(newData)
              addOrUpdateDirectoryInfo.mutateAsync({frpStudent: newData})
              createInfoToastAuto("Your directory information setting has been changed.")
              toast.dismiss(t.id);
            }} />
    ), { duration: Infinity })
  }
  
  return (
    <div style={{marginBottom: '100px'}} className="d-flex justify-content-center align-items-center">
      <div style={{maxWidth: '500px', }}>
        <h5 className="border bg-bold text-white rounded p-1">My Directory Information</h5>
        <div className="text-justify">
          <i className="bi bi-exclamation-triangle text-warning me-2"></i>
          Unless your safety is at risk, we do not generally recommend making your directory information confidential.
          Doing so makes your interactions with Snow College personnel much more difficult. It is, however, your right to restrict your directory information if you choose.
        </div>
        <hr/>
        <div className="mb-1 mt-3 text-center">
          <div className="p-2 border rounded bg-light-subtle ms-2 text-center" style={{maxWidth: '500px'}}>
            <div className="opacity-50">INSTRUCTIONS</div>
            <div className="mb-3">For most people, invoking the right is <b className="text-bold">generally not recommended because of the drawbacks</b> which include, but are not limited to:</div>
            <div className="mb-3 small text-left text-justify"><i className="bi bi-dash-circle-fill text-danger me-2"></i>College personnel cannot discuss anything with you over the phone or even confirm your enrollment at Snow Colleges <b className="text-bold">unless you first volunteer your badgerid and security phrase</b>. If you do not or can not volunteer this information over the phone, you must appear in person or contact us via email with your Snow College student email address.</div>
            <div className="mb-3 small text-left"><i className="bi bi-dash-circle-fill text-danger me-2"></i>Information regarding your student status will be suppressed, so that if prospective employer, family member, or anyone else inquires about you, they will be informed that we have no record of your attendance or existence at Snow College.</div>
            <div className="mb-3 small text-left"><i className="bi bi-dash-circle-fill text-danger me-2"></i>Your name will not appear on Dean's lists.</div>
            <div className="mb-3 small text-left"><i className="bi bi-dash-circle-fill text-danger me-2"></i>Your name will not appear in Snow College graduation/commencement programs, books, or publications.</div>
            <div className="mb-3 small text-left"><b><i>Though not generally recommended, it is your right to invoke this right as you see fit.</i></b>
          </div>
          <div className="d-flex justify-content-center my-auto">
            <div className="border rounded p-2 bg-dark-subtle" style={{maxWidth: '500px'}}>
              <div className="border rounded bg-white text-center text-black mb-3">DIRECTORY INFORMATION</div>
              <div className="justify-content-center text-left ms-2 me-2">
                <div><i className="bi bi-arrow-right me-2"></i>*Student name</div>
                <div><i className="bi bi-arrow-right me-2"></i>Telephone</div>
                <div><i className="bi bi-arrow-right me-2"></i>Personal email and student email</div>
                <div><i className="bi bi-arrow-right me-2"></i>Home city and state</div>
                <div><i className="bi bi-arrow-right me-2"></i>Address</div>
                <div><i className="bi bi-arrow-right me-2"></i>Verification of current enrollment
                <div><i className="bi bi-arrow-right me-2"></i>Dates of enrollment</div>
                <div><i className="bi bi-arrow-right me-2"></i>Degrees conferred</div>
                <div><i className="bi bi-arrow-right me-2"></i>Major or field of study/concentration</div>
                <div><i className="bi bi-arrow-right me-2"></i>Honors received</div>
              </div>
              <div className="opacity-50 mt-3">
                *The student name will not be disclosed outside of class participation. A students name and participation in class cannot be concealed from the instructor or other students who are also enrolled and participating in the class.
              </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="mb-5 mx-auto text-center w-100">
          {formData.isOffDirectoryInfo 
            ? <div className="mb-2 text-center">Your directory information is restricted to everyone. Click on the button below to cancel this restriction</div>
            : <div className="opacity-50 mb-2 text-center">Click on the button below to make your directory information hidden</div>
          }
          {formData.isOffDirectoryInfo 
            ? <button className="btn btn-info my-auto" style={{maxWidth: '200px'}} onClick={()=>addOrUpdate("Are you sure you want to cancel your confidential setting?")}>
                Cancel Confidential
              </button>
            : <button className="btn btn-warning my-auto" style={{maxWidth: '200px'}} onClick={()=>addOrUpdate("Are you sure that you want to take this extreme step to hide your directory information and all that implies?")}>
                Make Confidential
              </button>
          }
        </div>

      </div>
    </div>
  </div>    
	)
}

