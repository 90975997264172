import React, { useEffect, useState } from 'react'
import { createErrorToastHtml, createInfoToastAuto } from '../../services/queryClient';
import { Required } from '../../components/forms/Required/Required';
import { Spinner } from '../../components/ui/Spinner';
import { useNavigate } from 'react-router-dom';
import { FrpStudent } from '../../models/frp';
import { useAddOrUpdateFrpStudentPassphraseMutation, useGetFrpStudentSelfQuery } from '../../hooks/frpHooks';
import toast from 'react-hot-toast';
import { ConfirmationToast } from '../../components/ConfirmationToast';

export const FrpPassphraseAddOrUpdate = () => {
  const navigate = useNavigate()

  const addOrUpdateStudentPassphrase = useAddOrUpdateFrpStudentPassphraseMutation()
  const studentQuery = useGetFrpStudentSelfQuery()
  const student = studentQuery.data

  const [showPassphrase, setShowPassphrase] = useState<boolean>()
  const [formData, setFormData] = useState<FrpStudent>({
    frpStudentId: 0,
    passphrase: '',
  });

  const clearRelation = () => {
    setFormData({
      frpStudentId: 0,
      passphrase: '',
    }) 
  }

  useEffect(() => {
    if (student) {
      setFormData({
        frpStudentId: student.frpStudentId,
        passphrase: student.passphrase,
      })      
      setShowPassphrase(!student?.passphrase)
    }
    return () => clearRelation()
  }, [student])

  if (studentQuery.isLoading)
    return <Spinner />
  if (studentQuery.isError)
    return <h3>Error getting the FERPA student record</h3>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const name = target.name;
    const newText = event.target.value.replace(/[^a-zA-Z0-9\s]/g, "").replace(/\s+/g, " ");
    setFormData(prevState => ({...prevState,[name]: newText}))
  };
    
  const addOrUpdate = () =>  {
    let missingFields = ''
    if (!formData.passphrase) missingFields += "\nPassphrase"

    if (!missingFields) {
      addOrUpdateStudentPassphrase.mutateAsync({frpStudent: formData})
      createInfoToastAuto("The passphrase has been saved")
      clearRelation()
      navigate(-1)    
    } else {
      const isSuccess = true
      createErrorToastHtml(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`, isSuccess)
    }
  }

  const deleteConfirm = () => {
    toast((t) => (
      <ConfirmationToast
          toastId={t.id}
          message={`Are you sure you want to delete your passphrase?`}
          confirmHandler={() => {
            formData.passphrase = ''
            addOrUpdateStudentPassphrase.mutateAsync({frpStudent: formData})
            createInfoToastAuto(`Your passphrase has been deleted.`)
            toast.dismiss(t.id);
          }} />
    ), { duration: Infinity })
  }

    return (
    <div className="d-flex justify-content-center container" style={{marginBottom: '75px'}}>
      <div style={{maxWidth: '800px', marginBottom: '100px'}} className="justify-content-center">
        <h5 className="border bg-bold text-white rounded p-1">{formData.frpStudentId ? `Edit Passphrase` : `Add a New Passphrase`}</h5>
        <div className="text-justify">
          The Family Educational Rights and Privacy Act (FERPA), a federal privacy law, governs student education records and information privacy. 
          It puts you in control of who we share your non-directory student information with. Some exceptions apply. <b className="text-bold">This dashboard is where 
          you can control who can get information about your education records and student information.</b> It provides tools to simplify communication 
          with Snow College employees.
        </div>
        <div>
          <div className="mb-1 mt-3 d-flex flex-wrap">
            <div className="p-2 border rounded bg-light-subtle ms-2">
              <div className="opacity-50">INSTRUCTIONS</div>
              <div>When you interact with Snow College employees, they need to be able to briefly verify who you are.</div>
              <div className="mt-3 mb-4"><b className="text-bold">Choose a security phrase we can use to verify your identity.</b> Do not share it with anyone but Snow College employees when requested! Keep the following details in mind:</div>
              <div className="mb-2"><i className="bi bi-filter-circle text-bold me-2"></i>Do NOT use your mySnow password as your FERPA passphrase.</div>
              <div className="mb-2"><i className="bi bi-filter-circle text-bold me-2"></i>This phrase is delivered VERBALLY. Choose something that is easy to say, easy to  remember, and is not embarrassing to speak outloud.</div>
              <div className="mb-2"><i className="bi bi-filter-circle text-bold me-2"></i>Many Snow College employees will be able to see your phrase. Do not enter any text that is personal or sensitive.</div>
              <div className="mb-2"><i className="bi bi-filter-circle text-bold me-2"></i>Punctuation is not allowed.</div>
            </div>
          </div>
          <div className="d-flex justify-content-center w-100">
            <div className="mt-3">
              <label className="form-label d-flex">
                Security Passphrase<Required setWhen={!!formData.passphrase}/>
                <div className="fst-italics small opacity-50 ms-3 my-auto">
                  Do not use your My Snow password.
                </div>
              </label>
              {showPassphrase && 
                <input 
                    type="text"
                    className="form-control" 
                    name="passphrase"
                    value={formData.passphrase}
                    maxLength={25}
                    style={{width: '250px'}}
                    onChange={handleChange}
                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {handleChange(event); formData.passphrase && setShowPassphrase(false);}}
                />
              }
              {!showPassphrase && 
                <div className="d-flex" onMouseOver={() => setShowPassphrase(true)} 
                                        onClick={() => setShowPassphrase(true)} 
                                        onMouseOut={() => setShowPassphrase(false)}>
                    <div className="d-flex flex-nowrap"><i className="bi bi-eye-slash"/>***************</div>
                </div>
              }
            </div>
          </div>
          <div className="d-flex justify-content-end w-100 mt-4">
            <button className="btn btn-link btn-text bg-transparent border-0 me-2 text-decoration-none" onClick={() => navigate(-1)}>Close</button>
            {student?.passphrase && <button className="btn btn-outline-danger d-block me-3" onClick={deleteConfirm}>
                Remove
              </button>
            }
            <button className="btn btn-outline-bold d-block" onClick={addOrUpdate}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>    
	)
}

