import { FC } from "react";
import toast from "react-hot-toast";

export const ConfirmationToast: FC<{
  toastId: string,
  message: string
  confirmHandler: () => void
  denyHandler?: () => void
}> = ({ toastId, message, confirmHandler, denyHandler }) => {
  const cancelHandler = () => {
    denyHandler && denyHandler()
    toast.dismiss(toastId)
  }
  return (
    <div>
      <div className="text-center">
        {message}
      </div>
      <hr className="m-2" />
      <div className="row">
        <div className="col-6">
          <button
            className="btn btn-secondary w-100"
            onClick={cancelHandler}>
            No
          </button>
        </div>
        <div className="col">
          <button
            className="btn btn-bold w-100"
            onClick={confirmHandler}>
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}