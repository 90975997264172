import { FC } from "react"
import { createInfoToastAuto } from "../../services/queryClient"
import { FrpSessionSearch } from "../../models/frp"

interface Props {
  totalCount: number;
  sessionSearch: FrpSessionSearch;
  setSessionSearch: (c: FrpSessionSearch) => void;
}

export const Pagination: FC<Props> = ({
  totalCount,
  sessionSearch,
  setSessionSearch,
}) => {
  const movePointer = (nextOrPrev: string) => {
    if (nextOrPrev === "PREV") {
      if (sessionSearch.pagePointer > 1) {
        setSessionSearch({...sessionSearch, pagePointer: --sessionSearch.pagePointer})
        createInfoToastAuto(`Please wait a moment for the new data to arrive.`);
      }
    } else if (
      sessionSearch.pagePointer <
      Math.ceil(totalCount / sessionSearch.queryTake)
    ) {
      setSessionSearch({...sessionSearch, pagePointer: ++sessionSearch.pagePointer})
      createInfoToastAuto(`Please wait a moment for the new data to arrive.`)
    }
  };

  const handlePagePointer = (newPage: number) => {
    setSessionSearch({...sessionSearch, pagePointer: newPage})
    createInfoToastAuto(`Please wait a moment for the new data to arrive.`)
  };

  return (Math.ceil(totalCount / sessionSearch.queryTake) || 1) > 1 ? (
    <div className="container-fluid">
      <nav aria-label="breadcrumb" className="d-flex flex-row">
        <div className="me-4 d-flex">
          <div
            onClick={() => movePointer("PREV")}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-caret-left-square text-bold me-3" />
          </div>
          <ol className="breadcrumb">
            {Array.from(
              { length: Math.ceil(totalCount / sessionSearch.queryTake) || 1 },
              (_, index) => {
                if (sessionSearch.pagePointer === index + 1) {
                  return (
                    <li
                      className="breadcrumb-item active"
                      onClick={() => handlePagePointer(index + 1)}
                      style={{ cursor: "pointer" }}
                      key={`sub${index}`}
                    >
                      <div className="border bg-primary text-white rounded-1 px-1 py-0">
                        {index + 1}
                      </div>
                    </li>
                  );
                } else {
                  return (
                    <li
                      className="breadcrumb-item"
                      onClick={() => handlePagePointer(index + 1)}
                      style={{ cursor: "pointer" }}
                      key={`sub${index}`}
                    >
                      <div className="px-1 py-0">{index + 1}</div>
                    </li>
                  );
                }
              }
            )}
          </ol>
          <div
            onClick={() => movePointer("NEXT")}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-caret-right-square text-bold ms-3" />
          </div>
        </div>
      </nav>
    </div>
  ) : null;
};
