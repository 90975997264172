
export enum FrpSearchOnly {
  None = "None",
  Unverified = "Unverified",
  Failed = "Failed",
  Passed = "Passed",
}


export interface FrpAdmin {
	frpAdminId: number
	username: string
}

export interface FrpWorker {
    frpWorkerId: number
    emailAddress: string
    username?: string
    firstName?: string
    lastName?: string
}

export interface FrpStudent{
    frpStudentId: number
    badgerid?: string
    username?: string
    firstName?: string
    middleName?: string
    lastName?: string
    passphrase?: string
    isOffDirectoryInfo?: boolean
    lastUpdated?: Date
    sessions?: FrpSession[]
    talkingTo?: string
    delegates?: FrpDelegate[]
}

export interface FrpGeneratedCode{
    frpGeneratedCodeId: number
    frpSessionId: number
    cellNumber: string
    emailAddress: string
    code: string
    expireDate: Date
}


export interface FrpRelation{
    frpRelationId: number
    name: string
}


export interface FrpInfoType{
    frpInfoTypeId: number
    short: string
    title: string
    description: string
    sequence: number
}


export interface FrpDelegate{
    frpDelegateId: number
    frpStudentId?: number
    frpRelationId: number
    frpRelationName?: string
    firstName: string
    lastName: string
    cellNumber?: string
    emailAddress?: string
    passphrase?: string
    expireDate?: string
    lastUpdated?: Date
    frpInfoTypes?: number[]
}


export interface FrpDelegateInfoType{
    frpDelegateInfoTypeId: number
    frpDelegateId: number
    frpInfoTypeId: number
    lastUpdated: Date
}


export interface FrpVisitor{
    frpVisitorId: number
    userUsername: string
    page: string
    description: string
    recordType: string
    recordId: number
    entryDate: Date
}


export interface FrpSession{
    frpSessionId: number
    workerUsername: string
    talkingTo: string
    frpStudentId?: number
    passphrase?: string
    frpDelegateId?: number
    clientIp?: string
    passOrFail?: string
	emailCode?: string
    codeExpireDate?: Date
    entryDate?: Date
    isClosed?: boolean
    lastUpdated: Date
    studentFirstName?: string
    studentLastName?: string
    studentBadgerid?: string
    studentUsername?: string
    studentEmailAddress?: string
    studentPassphrase?: string
    delegateFirstName?: string
    delegateLastName?: string
    comments?: FrpComment[]
    isOffDirectoryInfo: boolean
    delegates?: FrpDelegate[]
    otherSessionsCount?: number
    totalCount?: number
}

export interface FrpSummarySearch {
    dateFromSearch?: string
    dateToSearch?: string
    firstNameSearch?: string
    lastNameSearch?: string
}


export interface FrpComment {
    frpCommentId?: number
    frpSessionId: number
    workerUsername?: string
    commentText: string
    entryDate?: Date
}


export interface FrpQuestion {
    frpQuestionId: number
    frpSessionId: number
    studentBadgerid: string
    question: string
    givenAnswer?: string
    entryDate: Date
    choices?: FrpQuestionChoice[]
}

export interface FrpQuestionChoice {
    frpQuestionChoiceId: number
    frpQuestionId: number
    choiceText: string
}

export interface FrpNewSession {
    talkingTo?: string
    frpStudentId: number
    badgerid: string
    emailAddress: string
    username: string
    firstName: string
    lastName: string
}

export interface IdLabelOption {
    id: string,
    label: string
}

export interface FrpSessionSearch {
    pagePointer: number
    queryTake: number
    unverifiedOnly?: boolean
    failedOnly?: boolean
    passedOnly?: boolean
    dateFromSearch?: string
    dateToSearch?: string
    firstNameSearch?: string
    lastNameSearch?: string
    badgeridSearch?: string
    usernameSearch?: string
    workerUsernameSearch?: string
}

export interface FrpFacilitatorSummary {
    firstName: string
    lastName: string
    lastSessionDate: Date
    sessions: FrpSession[]
}

