import toast from "react-hot-toast";
import { FrpSession } from "../../models/frp";
import { createInfoToastAuto } from "../../services/queryClient";
import { ConfirmationToast } from "../ConfirmationToast";
import { FC } from "react";
import { useUpdateFrpSessionIsClosedMutation } from "../../hooks/frpHooks";
import { useNavigate } from "react-router-dom";

interface Props {
  incomingFrpSessionId: number;
  session?: FrpSession;
  intervalId?: NodeJS.Timeout;
  setTimeLeft: (c: string) => void;
}

export const FrpSessionActiveClose: FC<Props> = ({
  incomingFrpSessionId,
  session,
  intervalId,
  setTimeLeft,
}) => {
  const navigate = useNavigate();
  const updateSessionIsClosed = useUpdateFrpSessionIsClosedMutation();

  const confirmToClose = () => {
    if (!session?.talkingTo) {
      createInfoToastAuto(
        "Please choose if you are talking to a student or a delegate before closing this session."
      );
    } else {
      toast(
        (t) => (
          <ConfirmationToast
            toastId={t.id}
            message={`Are you sure you want to close this session?`}
            confirmHandler={() => {
              updateSessionIsClosed.mutate({
                frpSessionId: incomingFrpSessionId,
                isClosed: true,
              });
              createInfoToastAuto(`The session has been closed.`);
              if (intervalId) {
                clearInterval(intervalId);
                setTimeLeft("00:00:00");
              }
              navigate(`/frp/sessions`);
              toast.dismiss(t.id);
            }}
          />
        ),
        { duration: Infinity }
      );
    }
  };

  return (
    <div style={{ minWidth: "350px" }}>
      <div className="mb-3 mt-2">
        {session?.isClosed && (
          <div className="me-2 my-auto fw-bold text-warning me-4 border rounded p-2 bg-light-subtle">
            This session has been closed.
          </div>
        )}
        {!session?.isClosed && (
          <div className="d-flex">
            <div className="me-2 my-auto me-4">
              If this session is resolved:
            </div>
            {session?.passOrFail ? (
              <button
                className="btn btn-outline-bold"
                onClick={confirmToClose}
                title="Close this session if it has been resolved."
              >
                Finish
              </button>
            ) : (
              <button
                className="btn btn-outline-secondary"
                onClick={() =>
                  createInfoToastAuto(
                    "Choose whether this session passed or failed before attempting to close."
                  )
                }
                title="It appears that this session is not yet resolved."
              >
                Finish
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
