import React, { useEffect, useState } from 'react'
import { createErrorToastHtml, createInfoToastAuto } from '../../services/queryClient';
import { Required } from '../../components/forms/Required/Required';
import { useNavigate, useParams } from 'react-router-dom';
import { FrpRelation } from '../../models/frp';
import { useAddOrUpdateFrpRelationMutation, useGetFrpRelationQuery } from '../../hooks/frpHooks';

export const FrpRelationAddOrUpdate = () => {
  const navigate = useNavigate()
  const params = useParams()

  const frpRelationId = Number(params.frpRelationId) || 0

  const addOrUpdateRelation = useAddOrUpdateFrpRelationMutation()
  const relationQuery = useGetFrpRelationQuery(frpRelationId || 0)
  const relation = relationQuery.data

  const [formData, setFormData] = useState<FrpRelation>({
    frpRelationId: 0,
    name: '',
  });

  const clearRelation = () => {
    setFormData({
    frpRelationId: 0,
    name: '',
    }) 
  }

  useEffect(() => {
    if (relation && relation.name) {
      setFormData({
        frpRelationId: relation.frpRelationId,
        name: relation.name,
      })      
    }
    return () => clearRelation()
  }, [relation])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData(prevState => ({...prevState,[name]: value}))
  };
    
  const addOrUpdate = () =>  {
    let missingFields = ''
    if (!formData.name) missingFields += "\nName"

    if (!missingFields) {
      addOrUpdateRelation.mutateAsync({frpRelation: formData})
      createInfoToastAuto("The relationship has been saved")
      clearRelation()
      navigate(`/frp/relations`)    
    } else {
      const isSuccess = true
      createErrorToastHtml(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`, isSuccess)
    }
  }

  return (
    <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
      <div style={{maxWidth: '600px', }} className="justify-content-center">
        <h5 className="border bg-bold text-white rounded p-1">{frpRelationId ? `Edit Relationship` : `Add a New Relationship`}</h5>
        <div>
          <div className="mb-1 mt-3">
            <label className="form-label">Name<Required setWhen={!!formData.name}/></label>
            <input 
                type="text"
                className="form-control" 
                name="name"
                value={formData.name}
                maxLength={25}
                style={{width: '150px'}}
                onChange={handleChange}
                onBlur={handleChange}
            />
          </div>
          <hr />
          <div className="d-flex justify-content-end w-100 mt-4">
            <button className="btn btn-link btn-text bg-transparent border-0 me-2 text-decoration-none" onClick={() => navigate(-1)}>Close</button>
            <button className="btn btn-outline-bold d-block" onClick={addOrUpdate}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>    
	)
}

