import { FC, useState } from "react"
import { FrpQuestion, FrpQuestionChoice, FrpSession } from "../../models/frp"
import { useGetFerpaQuestionsMutation } from "../../hooks/frpHooks"

interface Props {
  session?: FrpSession,
}

export const FrpSessionQuestions: FC<Props> = ({session}) => {
  const [questions, setQuestions] = useState<FrpQuestion[]>()
  const [answers, setAnswers] = useState<FrpQuestionChoice[]>()

  const getFerpaQuestions = useGetFerpaQuestionsMutation()

  const generateQuestions = () => {
    getFerpaQuestions.mutate({
      setQuestions, 
      studentBadgerid: session?.studentBadgerid || '', 
      frpSessionId: session?.frpSessionId || 0
    })
  }

  const isInfoTypeSelected = (frpQuestionChoice: FrpQuestionChoice) => {
    let result = answers?.filter(m => m.frpQuestionId === frpQuestionChoice.frpQuestionId && m.frpQuestionChoiceId === frpQuestionChoice.frpQuestionChoiceId)[0]
    return !!result
  }

  const isCorrect = (frpQuestionChoice: FrpQuestionChoice) => {
    const question = questions?.filter(m => m.frpQuestionId === frpQuestionChoice.frpQuestionId)[0]
    return question?.givenAnswer === frpQuestionChoice.choiceText ? <div className="text-success">correct</div> : <div className="text-danger">wrong</div>
  }

  const handleInfoTypeChange = (frpQuestionChoice: FrpQuestionChoice) => {
    //Since this is a radio control choice, delete any previous answer for this question and set the new or replacement answer.
    let newAnswers = answers && answers.length > 0 ? [...answers.filter((m: FrpQuestionChoice) => m.frpQuestionId !== frpQuestionChoice.frpQuestionId)] : []
    newAnswers = newAnswers && newAnswers.length > 0 ? newAnswers.concat(frpQuestionChoice) : [frpQuestionChoice]
    setAnswers(newAnswers)
  }

  return (
    <div>
      <div className="d-flex mb-2">
        <div className="my-auto">
          Ask three questions about the student: 
        </div>
        <button className="btn btn-outline-info ms-2" onClick={generateQuestions}>Generate</button>
      </div>
      {questions?.map((m: FrpQuestion, index: number) => 
        <div key={index} className="border rounded p-1 bg-light-subtle mb-2">
          <div>{`${index+1}. ${m.question}`}</div>
          {m.choices?.map((c: FrpQuestionChoice, index: number) => 
            <div className="d-flex" key={`choice${index}`} style={{cursor: 'pointer'}}>
              <div style={{position: 'relative', left: '5px', width: '50px'}}>
                {isInfoTypeSelected(c) ? isCorrect(c) : ''}
              </div>
              <input
                className="mt-1 border-bold ms-3 me-2"
                type="radio"
                name={String(m.frpQuestionId)}
                style={{fontSize: '24px'}}
                checked={isInfoTypeSelected(c)}
                onClick={() => handleInfoTypeChange(c)}
              />
              <div onClick={() => handleInfoTypeChange(c)}>{c.choiceText}</div>
            </div>
          )}
        </div>
      )}
    </div>
	)
}

