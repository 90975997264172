import { FC, useState } from "react"
import { FrpComment, FrpSession } from "../../models/frp"
import { ChannelGrayBar } from "../forms/ChannelGrayBar"
import { splitUsernameFirstLast } from "../../utils/stringFormat"
import { FormatMonthDayAtTimeNoSeconds } from "../../utils/dateConverter"
import { useAddOrUpdateFrpCommentMutation, useDeleteFrpCommentMutation } from "../../hooks/frpHooks"
import { createInfoToastAuto } from "../../services/queryClient"
import toast from "react-hot-toast"
import { ConfirmationToast } from "../ConfirmationToast"
import { useUser } from "../../hooks/userHooks"

interface Props {
  incomingFrpSessionId: number,
  session?: FrpSession,
}

export const FrpSessionInternalComments: FC<Props> = ({incomingFrpSessionId, session,}) => {
  const isMobile = window.innerWidth < 768
  const [internalComment, setInternalComment] = useState<string>('')
  const [editFrpComment, setEditFrpComment] = useState<FrpComment | null>()

  const addOrUpdateFrpComment = useAddOrUpdateFrpCommentMutation(incomingFrpSessionId)
  const deleteFrpComment = useDeleteFrpCommentMutation()
  const user = useUser()

  const handleSaveComment= () => {
    addOrUpdateFrpComment.mutate({frpCommentId: editFrpComment?.frpCommentId || 0, comment_text: internalComment})
    createInfoToastAuto("The comment has been saved.")
    setEditFrpComment(null)
    setInternalComment('')
  }

  const confirmDeleteComment = (frpCommentId: number) => {
    toast((t) => (
        <ConfirmationToast
            toastId={t.id}
            message={`Are you sure you want to delete this meeting?`}
            confirmHandler={() => {
                deleteFrpComment.mutate({frpCommentId})
                createInfoToastAuto(`The comment has been deleted.`)
                setEditFrpComment(null)
                setInternalComment('')
                toast.dismiss(t.id);
            }} />
    ), { duration: Infinity })
  }

  return (
    <div>
      <div style={{width: isMobile ? 'auto' : '650px'}}>
          {session?.comments && session?.comments.length > 0 && <ChannelGrayBar />}
          {session?.comments && session?.comments.length > 0 && <div className="opacity-50 small">INTERNAL COMMENTS</div>}
          {session?.comments?.map((m: FrpComment, index: number) => 
            <div key={index}>
              <div className="d-flex mb-2">
                <div className="fw-bold me-2">{splitUsernameFirstLast(m.workerUsername || '')}</div>
                <div className="small mt-1 me-3 opacity-50">{FormatMonthDayAtTimeNoSeconds(m.entryDate)}</div>
                {m.workerUsername === user.preferred_username &&
                  <div className="btn text-bold p-0 me-3" title="Edit this comment"
                      onClick={() => {setEditFrpComment(m); setInternalComment(m.commentText);}}>
                    <i className="bi bi-pencil d-block"></i>
                  </div>
                }
                {user.preferred_username === m.workerUsername &&
                  <div className="btn text-danger p-0" title="Delete this comment"
                       onClick={() => confirmDeleteComment(m.frpCommentId || 0)}>
                    <i className="bi bi-trash d-block"></i>
                  </div>
                }
              </div>
              <div>{m.commentText}</div>
              <hr className="mb-0" />
          </div>
          )}
      </div>
      <div className="pt-1 px-2 pb-3 mb-3 mt-2" style={{width: isMobile ? 'auto' : '650px'}}>
          <label className="form-label me-1 mb-0 mt-2">
          {editFrpComment ? 'Update Comment' : 'Add New Comment'}
          </label>
          <textarea 
            className="form-control" 
            name="internalComment"
            maxLength={1900}
            value={internalComment}
            onChange={(event) => setInternalComment(event.target.value)}
          />
          <div className="d-flex justify-content-end">
          <button className="btn btn-text btn-link btn-secondary mt-1 me-3" 
                  onClick={() => {
                    setEditFrpComment(null)
                    setInternalComment('')
                  }}>
            Clear
          </button>
          <button className="btn btn-outline-info mt-2" onClick={handleSaveComment}>Save Comment</button>
          </div>
      </div>
    </div>
	)
}
