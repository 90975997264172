import { useNavigate } from 'react-router-dom';
import { useGetFrpDelegatesQuery, useGetFrpStudentSelfQuery } from '../../hooks/frpHooks';

export const FrpStudentLanding = () => {
  const navigate = useNavigate()

  const studentQuery = useGetFrpStudentSelfQuery()
  const student = studentQuery.data
  const getFrpDelegatesQuery = useGetFrpDelegatesQuery(0)
  const delegates = getFrpDelegatesQuery.data

  if (!student) <h5>The user cannot be found</h5>

  const hasPathName = (partial: string) => {
    return window.location.pathname.indexOf('/' + partial) > -1
  }

  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-start">
        <div className="col-auto text-left">
          <div className="pb-4 list-group">
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('student/passphrase') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/student/passphrase`)}>
              <i className={`bi ${student?.passphrase ? 'bi-shield-check' : 'bi-shield-x'} pe-2`}></i>
              Security Passphrase
              {student?.passphrase 
                ? <span className="badge bg-success rounded-pill mt-1 text-white ms-1" title="Do you have a security phrase entered?">
                    <i className="bi bi-check-lg"></i>Yes
                  </span>
                : <span className="badge bg-warning rounded-pill mt-1 text-black ms-1" title="Do you have a security phrase entered?">
                    <i className="bi bi-dash-circle-fill text-danger me-1"></i>None
                  </span>
              }
            </div>
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action ${hasPathName('delegates') || hasPathName('addDelegate') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/delegates`)}>
              <div className='text-bold'>
                <i className="bi bi-share pe-2"></i>
                Share Info with Others
              </div>
              <span className={`my-auto ms-2 ${hasPathName('delegates') || hasPathName('addDelegate') ? "active text-white" : ""}`} title="How many delegates do you have?">
                ({delegates?.filter(m => !m.expireDate || new Date(m.expireDate) > new Date()).length || 0})
              </span>
            </div>
            <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('directoryInfo') ? "active text-white" : ""}`} 
                  onClick={() => navigate(`/frp/student/directoryInfo`)}>
              <i className={`bi ${student?.isOffDirectoryInfo ? 'bi-folder-x' : 'bi-folder-symlink'} pe-2`}></i>
              {student?.isOffDirectoryInfo ? <div className="text-decoration-line-through">My Directory Info</div> : 'My Directory Info'}
              {student?.isOffDirectoryInfo
                ? <span className="badge bg-warning rounded-pill mt-1 text-black ms-1" title="Do you have a security phrase entered?">
                    <i className="bi bi-dash-circle-fill text-danger me-1"></i>Hidden
                  </span>
                : <span className="badge bg-success rounded-pill mt-1 text-white ms-1" title="Do you have a security phrase entered?">
                    <i className="bi bi-check-lg me-1"></i>Available
                  </span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>    
	)
}

