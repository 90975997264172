import React, { useEffect, useState } from 'react'
import { createErrorToastHtml, createInfoToastAuto } from '../../services/queryClient';
import { Required } from '../../components/forms/Required/Required';
import { Spinner } from '../../components/ui/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { FrpDelegate, FrpInfoType, FrpRelation } from '../../models/frp';
import { useAddOrUpdateFrpDelegateMutation, useGetFrpDelegateQuery, useGetFrpInfoTypesQuery, useGetFrpRelationsQuery, useGetFrpStudentAndDelegatesQuery } from '../../hooks/frpHooks';
import { isValidEmail } from '../../utils/validate';
import { FormatYearMonthDay } from '../../utils/dateConverter';

export const FrpDelegateAddOrUpdate = () => {
  const navigate = useNavigate()
  const params = useParams()

  const frpDelegateId = params.frpDelegateId ? Number(params.frpDelegateId) : 0
  const frpStudentId = params.frpStudentId ? Number(params.frpStudentId) : 0

  const [formData, setFormData] = useState<FrpDelegate>({
    frpDelegateId: 0,
    frpRelationId: 0,
    firstName: '',
    lastName: '',
    emailAddress: '',
    passphrase: '',
    expireDate: '',
    frpInfoTypes: []
  });

  const studentAndDelegatesQuery = useGetFrpStudentAndDelegatesQuery(frpStudentId)
  const studentAndDelegates = studentAndDelegatesQuery.data
  const addOrUpdateDelegate = useAddOrUpdateFrpDelegateMutation()
  const getFrpRelationsQuery = useGetFrpRelationsQuery()
  const relations = getFrpRelationsQuery.data
  const getFrpInfoTypesQuery = useGetFrpInfoTypesQuery()
  const infoTypes = getFrpInfoTypesQuery.data
  const delegateQuery = useGetFrpDelegateQuery(frpDelegateId || 0)
  const delegate = delegateQuery.data

  const clearDelegate = () => {
    setFormData({
      frpDelegateId: 0,
      frpRelationId: 0,
      firstName: '',
      lastName: '',
      emailAddress: '',
      passphrase: '',
      expireDate: '',
      frpInfoTypes: []
    }) 
  }

  const isUniquePassphrase = (passphrase: string) => {
    if (!passphrase) return true
    const delegates = studentAndDelegates?.delegates?.filter(m => m.frpDelegateId !== frpDelegateId) || []
    const hasPassphrase = delegates?.filter(m => m.passphrase === passphrase)[0]
    const matchesStudent = studentAndDelegates?.passphrase === passphrase
    return !(hasPassphrase && hasPassphrase.passphrase) && !matchesStudent
  }

  useEffect(() => {
    if (delegate && delegate.firstName) {
      setFormData({
        frpDelegateId: delegate.frpDelegateId,
        frpRelationId: delegate.frpRelationId,
        firstName: delegate.firstName,
        lastName: delegate.lastName,
        emailAddress: delegate.emailAddress,
        passphrase: delegate.passphrase,
        expireDate: delegate.expireDate ? FormatYearMonthDay(delegate.expireDate, '-') : '',
        frpInfoTypes: delegate.frpInfoTypes,
      })      
    }
    return () => clearDelegate()
  }, [delegate])

  if (!!frpDelegateId) {
    if (delegateQuery.isLoading) return <Spinner />;
    if (delegateQuery.isError) return <h3>Error getting the facilitator details</h3>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData(prevState => ({...prevState,[name]: value}))
  };

  const handlePassphraseChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const name = target.name;
    const newText = event.target.value.replace(/[^a-zA-Z0-9\s]/g, "").replace(/\s+/g, " ");
    setFormData(prevState => ({...prevState,[name]: newText}))
  };

  const checkPassphrase = () => {
    if (formData.passphrase && !isUniquePassphrase(formData.passphrase)) {
      createInfoToastAuto("The delegate's passphrase is not unique compared to your passphrase or other delegates' passphrases, if any.")
    }
  }

    
  const handleEmailAddress = () => {
    if (!formData.emailAddress) {
      createInfoToastAuto("The delegate's email address is required.")
    } else if (formData.emailAddress && !isValidEmail(formData.emailAddress)) {
      createInfoToastAuto("The email address does not seem to be valid. Please check your entry and try again.")
    }
  };

  const addOrUpdate = () =>  {
    let missingFields = ''
    if (!formData.frpRelationId) missingFields += "\nRelationship"
    if (!formData.firstName) missingFields += "\nFirst name"
    if (!formData.lastName) missingFields += "\nLast name"
    if (!formData.emailAddress) missingFields += "\nEmail address"
    if (!formData.passphrase) missingFields += "\nPassphrase"
    if (!(formData.frpInfoTypes && formData.frpInfoTypes.length > 0)) missingFields += "\nOne or more information types"
    if (formData.passphrase && !isUniquePassphrase(formData.passphrase)) missingFields += "\nThe delegate's passphrase is not unique"

    if (!missingFields) {
      if (formData.expireDate) formData.expireDate = FormatYearMonthDay(formData.expireDate, '-')
      addOrUpdateDelegate.mutateAsync({frpDelegate: formData})
      createInfoToastAuto("The delegate has been saved")
      clearDelegate()
      navigate(`/frp/delegates`)    
    } else {
      const isSuccess = false
      createErrorToastHtml(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`, isSuccess)
    }
  }

  const handleInfoTypeChange = (frpInfoTypeId: number) => {
    let newInfoTypes = formData.frpInfoTypes && formData.frpInfoTypes.length > 0 ? [...formData.frpInfoTypes] : []
    if (newInfoTypes.indexOf(frpInfoTypeId) > -1) {
      let newInfoTypes = formData.frpInfoTypes?.filter(id => id !== frpInfoTypeId)
      setFormData({...formData, frpInfoTypes: newInfoTypes})
    } else {
      newInfoTypes = newInfoTypes && newInfoTypes.length > 0 ? newInfoTypes.concat(frpInfoTypeId) : [frpInfoTypeId]
      setFormData({...formData, frpInfoTypes: newInfoTypes})
    }
  }

  const isInfoTypeSelected = (frpIntoTypeId: number) => {
    return formData.frpInfoTypes && formData.frpInfoTypes.indexOf(frpIntoTypeId) > -1
  }

  const setAllClearAll = (value: Boolean)  => {
    let newChoice: number[] = []
    if (value) {
      infoTypes?.forEach((m: FrpInfoType) => {
        if (m.frpInfoTypeId) newChoice.push(m.frpInfoTypeId)
      })
    }
    setFormData({...formData, frpInfoTypes: newChoice})
  }

  return (
    <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
      <div style={{maxWidth: '600px', }} className="justify-content-center">
        <h5 className="border bg-bold text-white rounded p-1">{frpDelegateId ? `Edit Delegate` : `Add a New Delegate`}</h5>
        <div>
          <div className="d-flex">
            <div className="mb-1 mt-3 me-4">
              <label className="form-label">First name<Required setWhen={!!formData.firstName}/></label>
              <input 
                  type="text"
                  className="form-control" 
                  name="firstName"
                  value={formData.firstName}
                  maxLength={70}
                  onChange={handleChange}
              />
            </div>
            <div className="mb-1 mt-3">
              <label className="form-label">Last name<Required setWhen={!!formData.lastName}/></label>
              <input 
                  type="text"
                  className="form-control" 
                  name="lastName"
                  value={formData.lastName}
                  maxLength={70}
                  onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-n1 mt-3 mb-3">
            <label htmlFor="nameSearch" className="form-label small">Relationship<Required setWhen={!!formData.frpRelationId}/></label>
            <select
              id="frpRelationId"
              name="frpRelationId"
              style={{width: '250px'}}
              className="form-select form-select-sm"
              value={formData.frpRelationId}
              onChange={handleChange}
            >
              <option key={0} value={0}>- -</option>
              {relations?.map((m: FrpRelation) => 
                <option key={m.frpRelationId} value={m.frpRelationId}>
                  {m.name}
                </option>
              )}            
            </select>
          </div>
          <div className="mb-1 mt-3">
            <label className="form-label">Email address<Required setWhen={!!formData.emailAddress}/></label>
            <input 
                type="text"
                className="form-control" 
                name="emailAddress"
                value={formData.emailAddress}
                maxLength={190}
                onChange={handleChange}
                onBlur={handleEmailAddress}
            />
          </div>
          <div className="mb-1 mt-3">
            <label className="form-label d-flex">
              Passphrase<Required setWhen={!!formData.passphrase}/>
              <div className="ms-2 small opacity-50 my-auto">Should be unique to your passphrase and other delegates' passphrases.</div>
            </label>
            <input 
                type="text"
                className="form-control" 
                name="passphrase"
                value={formData.passphrase}
                maxLength={70}
                style={{width: '250px'}}
                onChange={handlePassphraseChange}
                onBlur={checkPassphrase}
            />
          </div>
          <div className="mb-1 mt-3">
            <label className="form-label d-flex my-auto">Expiration date<div className="ms-3 opacity-50 small my-auto">optional</div></label>
            <input 
              type="date"
              className="form-control form-control-sm"
              name="expireDate"
              style={{width: '150px'}}
              value={formData.expireDate}
              onChange={handleChange}
            />
          </div>
          <hr />
          <div className="d-flex col-12 mb-3 text-center flex-nowrap" style={{cursor: 'pointer'}}>
            <div className="text-bold me-2" onClick={() => setAllClearAll(true)}>set all</div>
            <div className="">|</div>
            <div className="text-bold ms-2" onClick={() => setAllClearAll(false)}>clear all</div>
          </div>
          {infoTypes?.map((m: FrpInfoType, index: number) =>
            <div className={`border rounded ${isInfoTypeSelected(m.frpInfoTypeId) ? 'bg-dark-subtle' : 'bg-light-subtle'} p-2 mb-2`} key={index} style={{cursor: 'pointer'}}>
              <div className="d-flex"> 
                <div className="form-check ms-2 me-3">
                  <input
                    className="form-check-input mt-1 border-bold"
                    type="checkbox"
                    style={{fontSize: '24px', cursor: 'pointer'}}
                    checked={isInfoTypeSelected(m.frpInfoTypeId)}
                    onChange={() => handleInfoTypeChange(m.frpInfoTypeId)}
                  />
                </div>
                <div onClick={() => handleInfoTypeChange(m.frpInfoTypeId)}>
                  <div>
                    <b>{m.title}</b>
                  </div>
                  <div>
                    {m.description}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end w-100 mt-4" style={{marginBottom: '100px'}}>
            <button className="btn btn-link btn-text bg-transparent border-0 me-2 text-decoration-none" onClick={() => navigate(-1)}>Close</button>
            <button className="btn btn-outline-bold d-block" onClick={addOrUpdate}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>    
	)
}

