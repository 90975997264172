export const DaysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const ShortDaysOfWeek = [
  "Sun",
  "Mon",
  "Tues",
  "Wed",
  "Thurs",
  "Fri",
  "Sat",
];

export const ShortMonthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

  export function FormatYearMonthDayTimeNoSeconds(
    value: Date | string | undefined
  ) {
    if (!value) return "";
    const date = new Date(value);
    return FormatYearMonthDay(date) + " " + FormatTimeNoSeconds(date);
  }

export function FormatMonthDayYearTimeNoSeconds(
  value: Date | string | undefined
) {
  if (!value) return "";
  const date = new Date(value);
  return FormatMonthDayYear(date) + " " + FormatTimeNoSeconds(date);
}

export function FormatMonthDayAtTimeNoSeconds(
  value: Date | string | undefined
) {
  if (!value) return "";
  const date = new Date(value);
  return FormatDayWeek(date) + " " + FormatTimeNoSeconds(date);
}

export function FormatShortDayOfWeekMonthDay(
  value: Date | string | undefined
) {
  if (!value) return "";
  const date = new Date(value);
  return ShortFormatDayWeek(date);
}

export function FormatShortDayOfWeekMonthDayTime(
  value: Date | string | undefined,
  hideAmPm?: boolean
) {
  if (!value) return "";
  const date = new Date(value);
  return ShortFormatDayWeek(date) + " " + FormatTimeNoSeconds(date, hideAmPm);
}

export function FormatDatetimeLocalInput(value: Date | string | undefined) {
  if (!value) return "";
  const date = new Date(value);
  return FormatYearMonthDay(date, "-") + "T" + FormatHourMinute(date);
}

export function FormatTimeNoSeconds(value: Date | string | undefined, hideAmPm?: boolean) {
  if (!value) return ""
  const date = new Date(value)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const amPm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'

  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString()
  let result = `${hours}:${formattedMinutes}`
  if (!hideAmPm) result += ` ${amPm}`
  return result
}

export function FormatHourMinute(value: Date | string | undefined) {
  if (!value) return "";
  const date = new Date(value);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
}

export function FormatDayWeek(value: Date | string | undefined) {
  if (!value) return "";
  const date = new Date(value);

  const formattedDate = `${DaysOfWeek[date.getDay()]}, ${
    ShortMonthsOfYear[date.getMonth()]
  } ${date.getDate()}`;
  return formattedDate;
}

export function ShortFormatDayWeek(value: Date | string | undefined) {
  if (!value) return "";
  const date = new Date(value);

  const formattedDate = `${ShortDaysOfWeek[date.getDay()]}, ${
    ShortMonthsOfYear[date.getMonth()]
  } ${date.getDate()}`;
  return formattedDate;
}

export function FormatYearMonthDay(
  value: Date | string | undefined,
  delimiter: string = "/"
) {
  if (!value) return "";
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year + delimiter + month + delimiter + day}`;
}

export function FormatMonthDayYear(
  value: Date | string | undefined,
  delimiter: string = "/"
) {
  if (!value) return "";
  const date = new Date(value);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${month + delimiter + day + delimiter + year}`;
}

export function FormatMonthDayAt(
  value: Date | string | undefined,
) {
  if (!value) return "";
  const date = new Date(value);
  const month = getAbbreviatedMonth(date)
  const day = String(date.getDate());
  return `${month} ${day} @`;
}

export function FormatMonthAbbrevDay(
  value: Date | string | undefined,
) {
  if (!value) return "";
  const date = new Date(value);
  const month = getAbbreviatedMonth(date)
  const day = String(date.getUTCDate());
  return `${month} ${day}`;
}

export function FormatMonthAbbrevDayYear(
  value: Date | string | undefined,
  excludeYear?: boolean | undefined
) {
  if (!value) return "";
  const date = new Date(value);
  const month = getAbbreviatedMonth(date)
  const day = String(date.getUTCDate());
  return excludeYear ? `${month} ${day}` : `${month} ${day}, ${date.getFullYear()}`
}

function getAbbreviatedMonth(date: Date) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return monthNames[date.getUTCMonth()];
}



export function FormatLongMonthDayTime(value: Date | string | undefined) {
  if (!value) return "";
  const date = new Date(value);
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return date.toLocaleString("en-US", options);
}

export function FormatDynamicDate(value: Date | string | undefined): string {
  if (value === undefined) {
    return "";
  }

  const dateValue = new Date(value);
  const now = new Date();
  const diffInDays = Math.floor(
    (now.getTime() - dateValue.getTime()) / (1000 * 3600 * 24)
  );

  if (diffInDays <= 0) {
    return FormatTimeNoSeconds(dateValue);
  } else if (diffInDays === 1) {
    return "Yesterday";
  } else if (diffInDays < now.getDay()) {
    return dateValue.toLocaleDateString(undefined, { weekday: "long" });
  } else {
    return dateValue.toLocaleDateString(undefined, {
      weekday: "short",
      month: "numeric",
      day: "numeric",
    });
  }
}

export function getCurrentAcademicYear(): string {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  if (currentMonth >= 1 && currentMonth <= 7) {
    const academicYearStart = (currentYear - 1) % 100; // Get the last two digits of the previous year
    const academicYearEnd = academicYearStart + 1; // Academic year ends in the next calendar year
    return `${academicYearStart}${academicYearEnd}`;
  }

  const academicYearStart = currentYear % 100; // Get the last two digits of the current year
  const academicYearEnd = academicYearStart + 1; // Academic year ends in the next calendar year
  return `${academicYearStart}${academicYearEnd}`;
}


// Function to format the Date object or string to 'YYYY-MM-DDTHH:MM'
export function formatDateToDatetimeLocal(date: Date | string | undefined | null): string {
  if (date instanceof Date) {
    return date.toISOString().slice(0, -1);
  }
  if (typeof date === "string" && date !== "") {
    return new Date(date).toISOString().slice(0, -1);
  }
  return "";
};


export function formatDateForHtmlControl(incomingDate: Date | string | number): string {
  const inputDate = new Date(incomingDate)
  const localDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 16);
}

export function displayEndTime(dateTo: string) {
    let toDate = new Date(dateTo)
    let hourDisplay: number = toDate.getHours();
    const ampm: string = hourDisplay >= 12 ? 'PM' : 'AM';
    hourDisplay = hourDisplay % 12;
    hourDisplay = hourDisplay ? hourDisplay : 12;
    const minuteDisplay: string = toDate.getMinutes() < 10 ? `0${toDate.getMinutes()}` : String(toDate.getMinutes());
    return `${hourDisplay}:${minuteDisplay} ${ampm}`;        
}

