import { Link } from "react-router-dom";
import { ThemeSelector } from "./ThemeSelector";

export const NavBar = () => {
  const isMobile = window.innerWidth < 768

  return (
    <>
      <nav className="bg-primary shadow">
          <div className="ps-2 d-flex">
            <Link to="/" className="navbar-brand">
              <img
                className="img-fluid"
                id="navbar-logo"
                alt="Snow College Logo"
                src="/SnowCollegeLogo.png" 
                style={{width: isMobile ? '150px' : 'auto'}} />
            </Link>
            <div className="text-white my-auto fs-2" style={{marginLeft: isMobile ? '10px' : '100px'}}>
              FERPA
            </div>
            {!isMobile && <div className="text-white ps-5 my-auto fs-5">Family Educational Rights and Privacy Act</div>}
            <div className="border rounded bg-light ps-2 pe-2 my-md-auto ml-auto me-2" 
                 style={{height: '40px' }}>
              <div className="d-sm-none" style={{display: isMobile ? 'none' : 'inline', visibility: isMobile ? 'hidden' : 'visible'}}>Theme:</div> <ThemeSelector />
            </div>
        </div>
      </nav>
    </>
  )
}
