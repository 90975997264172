import { useNavigate } from 'react-router-dom';
import { useIsFrpAdminQuery } from '../../hooks/frpHooks';
// import { FrpSessionsOpen } from './FrpSessionsOpen';
// import { FrpSessionNone } from './FrpSessionsNone';

export const FrpWorkerLanding = () => {
  const navigate = useNavigate()

  // const getFrpSessionsActiveWorkerQuery = useGetFrpSessionsActiveWorkerQuery()
  // const sessions = getFrpSessionsActiveWorkerQuery.data
  const isFrpAdminQuery = useIsFrpAdminQuery()
  const isFrpAdmin = isFrpAdminQuery.data


  const hasPathName = (partial: string) => {
    return window.location.pathname.indexOf('/' + partial) > -1
  }

  return (
    <div className="container-fluid">
      {/* <div className="justify-content-start">
        <div>
          {!(sessions && sessions.length > 0) 
            ? <FrpSessionNone />
            : <FrpSessionsOpen openSessions={sessions} />
            
          }
        </div>
      </div> */}
      {!isFrpAdmin && 
        <div className="mb-3">
          <div className="row d-flex justify-content-start">
            <div className="col-auto text-left">
              <div className="ps-1 pt-4 list-group">
                <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('session/add') ? "active text-white" : ""}`} 
                      onClick={() => navigate(`/frp/session/add`)}>
                  <i className="bi bi-buildings pe-2"></i>
                  Add New Session
                </div>
                <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('sessions') ? "active text-white" : ""}`} 
                      onClick={() => navigate(`/frp/sessions`)}>
                  <i className="bi bi-buildings pe-2"></i>
                  Session List
                </div>
                <div className={`btn dropdown-item d-flex border-0 ms-2 mt-1 me-2 py-2 list-group-item list-group-item-action text-bold ${hasPathName('frp/page/visitors') ? "active text-white" : ""}`} 
                      onClick={() => navigate(`/frp/page/visitors`)}>
                  <i className="bi bi-hourglass-bottom pe-2"></i>
                  Visitor Page History
                </div>
              </div>
            </div>
          </div>
        </div>    
      }
    </div>    
  	)
}

