import React, { useCallback, useEffect, useState } from 'react'
import { createErrorToastHtml, createInfoToastAuto } from '../../services/queryClient';
import { Required } from '../../components/forms/Required/Required';
import { Spinner } from '../../components/ui/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { FrpInfoType } from '../../models/frp';
import { useAddOrUpdateFrpInfoTypeMutation, useGetFrpInfoTypeQuery, useGetFrpInfoTypesQuery } from '../../hooks/frpHooks';

export const FrpInfoTypeAddOrUpdate = () => {
  const navigate = useNavigate()
  const params = useParams()

  const frpInfoTypeId = Number(params.frpInfoTypeId) || 0

  const addOrUpdateInfoType = useAddOrUpdateFrpInfoTypeMutation()
  const infoTypeQuery = useGetFrpInfoTypeQuery(frpInfoTypeId || 0)
  const infoType = infoTypeQuery.data
  const getFrpInfoTypesQuery = useGetFrpInfoTypesQuery()
  const frpInfoTypes = getFrpInfoTypesQuery.data

  const [formData, setFormData] = useState<FrpInfoType>({
    frpInfoTypeId: 0,
    short: '',
    title: '',
    description: '',
    sequence: 0,
  });

  const clearInfoType = useCallback(() => {
    setFormData({
      frpInfoTypeId: 0,
      short: '',
      title: '',
      description: '',
      sequence: (frpInfoTypes && frpInfoTypes.length + 1) || 1,
    });
  }, [frpInfoTypes]); 

  useEffect(() => {
    if (infoType && infoType.title) {
      setFormData({
        frpInfoTypeId: infoType.frpInfoTypeId,
        short: infoType.short,
        title: infoType.title,
        description: infoType.description,
        sequence: infoType.sequence,
      });
    }
    return () => clearInfoType();
  }, [infoType, clearInfoType]);

  if (infoTypeQuery.isLoading || getFrpInfoTypesQuery.isLoading)
    return <Spinner />
  if (infoTypeQuery.isError || getFrpInfoTypesQuery.isError)
    return <h3>Error getting the FERPA information type</h3>;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData(prevState => ({...prevState,[name]: value}))
  };
    
  const addOrUpdate = () =>  {
    let missingFields = ''
    if (!formData.short) missingFields += "\nShort"
    if (!formData.title) missingFields += "\nTitle"
    if (!formData.description) missingFields += "\nDescription"

    if (!missingFields) {
      if (!formData.sequence) formData.sequence = (frpInfoTypes && frpInfoTypes.length+1) || 1
      addOrUpdateInfoType.mutateAsync({frpInfoType: formData})
      createInfoToastAuto("The information type has been saved")
      clearInfoType()
      navigate(`/frp/infoTypeList`)    
    } else {
      const isSuccess = true
      createErrorToastHtml(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`, isSuccess)
    }
  }

  return (
    <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
      <div style={{maxWidth: '600px', }} className="justify-content-center">
        <h5 className="border bg-bold text-white rounded p-1">{frpInfoTypeId ? `Edit Information Type` : `Add a New Information Type`}</h5>
        <div>
          <div className="mb-1 mt-3">
            <label className="form-label">Short<Required setWhen={!!formData.short}/></label>
            <input 
                type="text"
                className="form-control" 
                name="short"
                value={formData.short}
                maxLength={25}
                style={{width: '150px'}}
                onChange={handleChange}
                onBlur={handleChange}
            />
          </div>
          <div className="mb-1 mt-3">
            <label className="form-label">Title<Required setWhen={!!formData.title}/></label>
            <input 
                type="text"
                className="form-control" 
                name="title"
                value={formData.title}
                maxLength={190}
                onChange={handleChange}
                onBlur={handleChange}
            />
          </div>
          <div className="mb-1 mt-3 me-4">
            <label className="form-label">Description<Required setWhen={!!formData.description}/></label>
            <textarea
              className="form-control form-control-sm"
              name="description"
              value={formData.description}
              cols={80}
              rows={5}
              maxLength={1000}
              onChange={handleChange}
            />
          </div>
          <div className="mb-1 mt-3">
            <label className="form-label">Sequence<Required setWhen={!!formData.sequence}/></label>
            <select
              id="sequence"
              name="sequence"
              className="form-select form-select-sm"
              style={{width: '100px'}}
              value={formData.sequence ? formData.sequence : (frpInfoTypes && frpInfoTypes.length+1) || 1}
              onChange={handleChange}
            >
              {Array.from({ length: (frpInfoTypes && frpInfoTypes.length+2) || 1 }, (_, index) => {
                  if (index > 0) return <option key={index} value={index} >{index}</option>
                  return null
              })}            
            </select>
          </div>
          <hr />
          <div className="d-flex justify-content-end w-100 mt-4">
            <button className="btn btn-link btn-text bg-transparent border-0 me-2 text-decoration-none" onClick={() => navigate(-1)}>Close</button>
            <button className="btn btn-outline-bold d-block" onClick={addOrUpdate}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>    
	)
}

