import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useSearchParams } from "react-router-dom"
import { redirect_landing } from "../../AuthRequired";


export const LoginLanding = () => {
  const [_, _setSearchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const landing = localStorage.getItem(redirect_landing) ?? "/";
  useEffect(() => {
    if (landing && auth.user) {
      localStorage.removeItem(redirect_landing)
      navigate(landing)
    }
  }, [landing, auth.user, navigate])

  return (
    <div>Session refreshed. Rerouting...</div>
  )
}