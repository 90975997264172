import React, { FC } from 'react';

interface Props {
    label: string,
    text: string | number | undefined,
    jsx?: React.ReactNode,
    noDisplayIfBlank?: boolean,
    className?: string;
    keyIndex?: number;
    title?: string
}

export const TextDisplay: FC<Props> = ({text, label, noDisplayIfBlank, className, jsx, keyIndex, title}) => {
    return noDisplayIfBlank && !text ? null : (
        <div className={className ? className : "my-auto"} key={keyIndex} title={title}>
            <div className="opacity-50 small">{label}</div>
            <div className="" >{text}</div>
            <div className="" >{jsx}</div>
        </div>
    )
}
