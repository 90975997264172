import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { axiosClient } from "../utils/axiosClient";
import {
  FrpDelegate,
  FrpFacilitatorSummary,
  FrpInfoType,
  FrpNewSession,
  FrpRelation,
  FrpSession,
  FrpSessionSearch,
  FrpStudent,
  FrpSummarySearch,
  FrpWorker,
} from "../models/frp";
import { camel_to_snake } from "../utils/apiMapper";

export const frpService = {
  async isFrpAdmin(authHeader: AxiosRequestHeaders) {
    const url = `/api/frp/admin/is`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async isFrpWorker(authHeader: AxiosRequestHeaders) {
    const url = `/api/frp/worker/is/worker`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  // Workers ***********************************

  async addOrUpdateFrpWorker(
    frpWorker: FrpWorker,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/worker/addOrUpdate`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(frpWorker);
    const response = await axios.post(url, body, options);
    return response.data;
  },

  async getFrpWorker(
    frpWorkerId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpWorker | null> {
    if (!frpWorkerId) return null;
    const url = `/api/frp/worker/get/id/${frpWorkerId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFrpWorkers(authHeader: AxiosRequestHeaders): Promise<FrpWorker[]> {
    const url = `/api/frp/worker/get/all`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async deleteFrpWorker(frpWorkerId: number, authHeader: AxiosRequestHeaders) {
    const url = `/api/frp/worker/delete/${frpWorkerId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  // Info Types *************************************

  async getFrpInfoTypes(
    authHeader: AxiosRequestHeaders
  ): Promise<FrpInfoType[]> {
    const url = `/api/frp/admin/info/types`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async addOrUpdateInfoType(
    frpInfoType: FrpInfoType,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/admin/info/type/addorUpdate`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(frpInfoType);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async deleteFrpInfoType(
    frpInfoTypeId: number,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/admin/info/type/delete/${frpInfoTypeId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    await axios.get(url, options);
  },

  async getFrpInfoType(
    frpInfoTypeId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpInfoType> {
    const url = `/api/frp/admin/info/type/${frpInfoTypeId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  // Relationships *************************************

  async getFrpRelations(
    authHeader: AxiosRequestHeaders
  ): Promise<FrpRelation[]> {
    const url = `/api/frp/admin/relations`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async addOrUpdateRelation(
    frpRelation: FrpRelation,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/admin/relation/addorUpdate`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(frpRelation);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async deleteFrpRelation(
    frpRelationId: number,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/admin/relation/delete/${frpRelationId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    await axios.get(url, options);
  },

  async getFrpRelation(
    frpRelationId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpRelation> {
    const url = `/api/frp/admin/relation/${frpRelationId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  // Students *************************************

  async getFrpStudentAndDelegates(
    frpStudentId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpStudent | null> {
    if (!frpStudentId) return null;
    const url = `/api/frp/student/and/delegates/${frpStudentId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFrpStudentSelf(
    authHeader: AxiosRequestHeaders
  ): Promise<FrpStudent> {
    const url = `/api/frp/student/self`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async addOrUpdatePassphraseStudent(
    frpStudent: FrpStudent,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/student/passphrase/addorUpdate`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(frpStudent);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async addOrUpdateFrpDirectoryInfo(
    frpStudent: FrpStudent,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/student/directoryInfo/addorUpdate`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(frpStudent);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  // Delegates *************************************

  async getFrpDelegate(
    frpDelegateId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpDelegate> {
    const url = `/api/frp/delegate/get/${frpDelegateId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFrpDelegates(
    frpStudentId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpDelegate[]> {
    const url = `/api/frp/delegate/student/${frpStudentId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFrpDelegateSelf(
    authHeader: AxiosRequestHeaders
  ): Promise<FrpDelegate[]> {
    const url = `/api/frp/delegate/self`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async addOrUpdateDelegate(
    frpDelegate: FrpDelegate,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/delegate/addorUpdate`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(frpDelegate);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async deleteFrpDelegate(
    frpDelegateId: number,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/delegate/delete/${frpDelegateId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    await axios.get(url, options);
  },

  // Sessions *************************************

  async getFrpSessionsActiveWorker(
    authHeader: AxiosRequestHeaders
  ): Promise<FrpSession[]> {
    const url = `/api/frp/session/active/worker`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async searchFrpSessions(
    sessionSearch: FrpSessionSearch | undefined,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpSession[]> {
    const url = `/api/frp/session/admin/get`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(sessionSearch);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async searchFrpFacilitatorSummary(
    summarySearch: FrpSummarySearch,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpFacilitatorSummary[]> {
    const url = `/api/frp/session/facilitator/summary`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(summarySearch);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async getFrpSessionWorkerUsernames(
    authHeader: AxiosRequestHeaders
  ): Promise<FrpSession[]> {
    const url = `/api/frp/session/worker/usernames`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFrpSession(
    frpSessionId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpSession> {
    const url = `/api/frp/session/id/${frpSessionId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async addFrpSession(
    frpNewSession: FrpNewSession,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/new`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(frpNewSession);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async addSessionNoFrpStudent(
    student: FrpStudent,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/new/noFrpStudent`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = camel_to_snake(student);
    const response = await axiosClient.post(url, body, options);
    return response.data;
  },

  async searchFrpStudents(
    firstNameSearch: string,
    lastNameSearch: string,
    badgeridSearch: string,
    usernameSearch: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/search/student/${
      firstNameSearch.trim() || "~"
    }/${lastNameSearch.trim() || "~"}/${badgeridSearch.trim() || "~"}/${
      usernameSearch.trim() || "~"
    }`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFrpSessionActive(
    frpSessionId: number,
    authHeader: AxiosRequestHeaders
  ): Promise<FrpSession> {
    const url = `/api/frp/session/active/${frpSessionId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async chooseSessionDelegate(
    frpSessionId: number,
    frpDelegateId: number,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/update/delegate/${frpSessionId}/${frpDelegateId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async updateFrpSessionTalkingTo(
    frpSessionId: number,
    talkingTo: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/update/talkingTo/${frpSessionId}/${talkingTo}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async updateFrpSessionPassFail(
    frpSessionId: number,
    passOrFail: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/update/passFail/${frpSessionId}/${passOrFail}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async updateFrpSessionIsClosed(
    frpSessionId: number,
    isClosed: boolean,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/update/isClosed/${frpSessionId}/${isClosed}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async updateFrpSessionEmailCode(
    frpSessionId: number,
    emailCode: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/update/emailCode/${frpSessionId}/${emailCode}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async addOrUpdateFrpComment(
    frpSessionId: number,
    frpCommentId: number,
    commentText: string,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/comment/addOrUpdate`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const body = {
      frp_session_id: frpSessionId,
      frp_comment_id: frpCommentId,
      comment_text: commentText,
    };
    const response = await axios.post(url, body, options);
    return response.data;
  },

  async deleteFrpComment(
    frpCommentId: number,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/comment/delete/${frpCommentId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFrpVisitors(authHeader: AxiosRequestHeaders) {
    const url = `/api/frp/admin/page/visitors`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getConvertFerpa(authHeader: AxiosRequestHeaders) {
    const url = `/api/frp/student/convert/ferpa`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async getFerpaQuestions(
    studentBadgerid: string,
    frpSessionId: number,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/student/questions/get/${studentBadgerid}/${frpSessionId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async updateSessionLastDate(
    frpSessionId: number,
    authHeader: AxiosRequestHeaders
  ) {
    const url = `/api/frp/session/update/last/${frpSessionId}`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },

  async expireOldSessions(authHeader: AxiosRequestHeaders) {
    const url = `/api/frp/session/expire/old`;
    const options: AxiosRequestConfig = { headers: authHeader };
    const response = await axiosClient.get(url, options);
    return response.data;
  },
};
