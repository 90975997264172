import React, { useEffect, useState } from 'react'
import { createErrorToastHtml, createInfoToastAuto } from '../../services/queryClient';
import { Required } from '../../components/forms/Required/Required';
import { Spinner } from '../../components/ui/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { FrpWorker } from '../../models/frp';
import { useAddOrUpdateFrpWorkerMutation, useGetFrpWorkerQuery } from '../../hooks/frpHooks';
import { splitUsernameFirstOnly, splitUsernameLastOnly } from '../../utils/stringFormat';

export const FrpWorkerAddOrUpdate = () => {
  const navigate = useNavigate()
  const params = useParams()

  const frpWorkerId = Number(params.frpWorkerId) || 0

  const [formData, setFormData] = useState<FrpWorker>({
    frpWorkerId: 0,
    emailAddress: '',
    username: '',
    firstName: '',
    lastName: '',
  });

  const addOrUpdateWorker = useAddOrUpdateFrpWorkerMutation()
  const workerQuery = useGetFrpWorkerQuery(frpWorkerId || 0)
  const worker = workerQuery.data

  const clearWorker = () => {
    setFormData({
      frpWorkerId: 0,
      emailAddress: '',
      username: '',
      firstName: '',
      lastName: '',
    }) 
  }

  useEffect(() => {
    if (worker && worker.username) {
      setFormData({
        frpWorkerId: worker.frpWorkerId,
        emailAddress: worker.emailAddress,
        username: worker.username,
        firstName: worker.firstName,
        lastName: worker.lastName,
      })      
    }
    return () => clearWorker()
  }, [worker])

  if (!!frpWorkerId) {
    if (workerQuery.isLoading) return <Spinner />;
    if (workerQuery.isError) return <h3>Error getting the facilitator details</h3>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData(prevState => ({...prevState,[name]: value}))
  };

    
  const handleEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.value.toLowerCase().indexOf('snow.edu') === -1) {
      createInfoToastAuto("The email address must be a Snow College address:  '...snow.edu'")
    } else {
      let newState = {...formData}
      const username = formData.emailAddress.substring(0, formData.emailAddress.indexOf('@'))
      newState.username = username
      if (!newState.firstName) newState.firstName = splitUsernameFirstOnly(username)
      if (!newState.lastName) newState.lastName = splitUsernameLastOnly(username)
      setFormData(newState)
    }
  };

  const addOrUpdate = () =>  {
    let missingFields = ''
    if (!formData.emailAddress) {
      missingFields += "\nFacilitator's email address"
    } else if (!formData.emailAddress) {
      missingFields += "\nMust be a Snow College email address"
    }
    if (!formData.firstName) missingFields += "\nFirst name"
    if (!formData.lastName) missingFields += "\nLast name"

    if (!missingFields) {
      addOrUpdateWorker.mutateAsync({frpWorker: formData})
      createInfoToastAuto("The facilitator has been saved")
      clearWorker()
      navigate(`/frp/workers`)    
    } else {
      const isSuccess = true
      createErrorToastHtml(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`, isSuccess)
    }
  }

  return (
    <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
      <div style={{maxWidth: '600px', }} className="justify-content-center">
        <h5 className="border bg-bold text-white rounded p-1">{frpWorkerId ? `Edit Facilitator` : `Add a New Facilitator`}</h5>
        <div>
          <div className="mb-1 mt-3">
            <label className="form-label">Snow College Email address<Required setWhen={!!formData.emailAddress}/></label>
            <input 
                type="text"
                className="form-control" 
                name="emailAddress"
                value={formData.emailAddress}
                maxLength={190}
                onChange={handleChange}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => handleEmailAddress(event)}
            />
          </div>
          <div className="d-flex">
            <div className="mb-1 mt-3 me-4">
              <label className="form-label">First name<Required setWhen={!!formData.firstName}/></label>
              <input 
                  type="text"
                  className="form-control" 
                  name="firstName"
                  value={formData.firstName}
                  maxLength={70}
                  onChange={handleChange}
              />
            </div>
            <div className="mb-1 mt-3">
              <label className="form-label">Last name<Required setWhen={!!formData.lastName}/></label>
              <input 
                  type="text"
                  className="form-control" 
                  name="lastName"
                  value={formData.lastName}
                  maxLength={70}
                  onChange={handleChange}
              />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-end w-100 mt-4">
            <button className="btn btn-link btn-text bg-transparent border-0 me-2 text-decoration-none" onClick={() => navigate(-1)}>Close</button>
            <button className="btn btn-outline-bold d-block" onClick={addOrUpdate}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>    
	)
}

