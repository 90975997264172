import { FC } from 'react';
import { useSendFrpEmailCodeMutation } from '../../hooks/frpHooks';
import { FrpSession } from '../../models/frp';
import { createInfoToastAuto } from '../../services/queryClient';

interface Props {
  isMobile: boolean,
  emailCode?: number,
  setEmailCode: (c: number) => void,
  timeLeft: string, 
  setTimeLeft: (c: string) => void,  
  intervalId?: NodeJS.Timeout,
  setIntervalId: (c: NodeJS.Timeout) => void,
  session?: FrpSession,
  talkingTo: string
}

export const FrpSessionActiveEmailCode: FC<Props> = ({isMobile, emailCode, setEmailCode, timeLeft, setTimeLeft, intervalId, setIntervalId, session, talkingTo}) => {
  const sendFrpEmailCode = useSendFrpEmailCodeMutation(session?.frpSessionId || 0)

  const sendEmailCode = () => {
    if (!talkingTo) {
        createInfoToastAuto("Please choose if you are talking to a student or a delegate before sending an email code.")
    } else {
      const newEmailCode = Math.floor(Math.random() * 900000) + 100000
      let currentDate = new Date()
      currentDate.setMinutes(currentDate.getMinutes() + 5)
      setEmailCode(newEmailCode)
      sendFrpEmailCode.mutate({emailCode: String(newEmailCode)})
      if (intervalId) clearInterval(intervalId)
      const newIntervalId = setInterval(() => showExpireDate(currentDate), 1000)
      setIntervalId(newIntervalId)
    }
  }

  const showExpireDate = (expireDate: Date) => {
      const now = new Date()
      const difference = expireDate.getTime() - now.getTime() || 0

      if (difference > 0) {
          const minutes = Math.floor((difference / 1000 / 60) % 60)
          const seconds = Math.floor((difference / 1000) % 60)

          setTimeLeft(`${minutes.toString()}:${seconds.toString().padStart(2, '0')}`)
      } else {
          intervalId && clearInterval(intervalId)
          setTimeLeft('00:00:00')
      }
  }


  return (
    <div style={{minWidth: '350px'}}>
        {session?.isClosed &&
            <div className={`d-flex mb-3 mt-2 ${isMobile ? 'flex-column' : 'flex-row'}`}>
                <div className="me-2 my-auto">{session?.emailCode ? 'An email code was sent in this session.' : 'An email code was not used in this session.'}</div>
            </div>
        }
        {!session?.isClosed &&
            <div>
                <div className={`d-flex mb-3 mt-2 ${isMobile ? 'flex-column' : 'flex-row'}`}>
                    <div className="me-2 my-auto d-flex flex-wrap">
                        {`Send a code to the ${talkingTo ? talkingTo + "'s" : ''} email on record?`}
                        {!talkingTo && <div className="fst-italic ms-2">(A student or delegate has not yet been chosen.)</div>}
                    </div>
                    <button className={`btn btn-outline-bold ms-4 mx-auto ${isMobile ? 'ms-3' : ''}`} onClick={sendEmailCode}>
                        Send Code
                    </button>
                </div>
                {emailCode &&
                    <div className="d-flex flex-nowrap">
                        <div className="border rounded p-1 bg-light-subtle ms-5 my-auto">{emailCode}</div>
                        <div className="ms-2 me-2 my-auto">Time left:</div>
                        <div className="text-danger my-auto">{timeLeft}</div>
                    </div>
                }
            </div>
        }
    </div>
	)
}

