import { FC } from "react";
import { FrpSession } from "../../models/frp";
import { createInfoToastAuto } from "../../services/queryClient";
import { useUpdateFrpSessionPassFailMutation } from "../../hooks/frpHooks";

interface Props {
  incomingFrpSessionId: number,
  intervalId?: NodeJS.Timeout,
  isMobile: boolean,
  talkingTo: string,
  session?: FrpSession,
  setTimeLeft: (c: string) => void,
}

export const FrpSessionPassOrFail: FC<Props> = ({incomingFrpSessionId, intervalId, isMobile, talkingTo, session, setTimeLeft }) => {
  const updateSessionPassFail = useUpdateFrpSessionPassFailMutation(incomingFrpSessionId);

  const handlePassFail = (passOrFail: string) => {
    if (!session?.talkingTo) {
        createInfoToastAuto("Please choose if you are talking to a student or a delegate before choosing pass or fail.")

    } else if (talkingTo === "Delegate" && !session?.frpDelegateId) {
      createInfoToastAuto(
        "You have chosen 'Delegate' but a delegate is not chosen. Please check your entry and try again."
      );
      
    } else {
      updateSessionPassFail.mutate({ passOrFail });
      if (intervalId) {
        clearInterval(intervalId);
        setTimeLeft("00:00:00");
      }
    }
  };

  return (
    <div style={{ minWidth: "350px" }}>
      <div
        className={`d-flex mb-3 mt-2 ${isMobile ? "flex-column" : "flex-row"}`}
      >
        {!session?.isClosed && 
          <div className="me-2 my-auto">{talkingTo ? `Is the ${talkingTo} verified?` : ''}</div>
        }
        <div className={`d-flex ${isMobile ? "ms-3" : ""}`}>
          {session?.passOrFail === "Pass" ? (
            <div className="d-flex flex-nowrap">
              <i className="bi bi-check-lg text-warning ms-1"></i>
              <div className="text-success fs-4">Passed</div>
            </div>
          ) : session?.isClosed ? null : (
            <button
              className="btn btn-outline-success ms-4"
              onClick={() => handlePassFail("Pass")}
            >
              Pass
            </button>
          )}
          {session?.passOrFail === "Fail" ? (
            <div className="d-flex flex-nowrap ms-2">
              <i className="bi bi-dash-circle-fill text-warning ms-1 my-auto me-1"></i>
              <div className="text-danger fs-4">Failed</div>
            </div>
          ) : session?.isClosed ? null : (
            <button
              className="btn btn-outline-danger ms-4"
              onClick={() => handlePassFail("Fail")}
            >
              Fail
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
