import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { AuthRequired } from "./AuthRequired";
import { LoginLanding } from "./pages/ferpa/LoginLanding";

function App() {
  return (
    <Routes>
      <Route path="/login/landing" element={<LoginLanding />} />
      <Route path="*" element={<AuthRequired><AppRoutes /></AuthRequired>} />
    </Routes>
  )
}
export default App;
