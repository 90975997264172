import { FC } from "react"

interface Props {
  className?: string
}

export const ChannelGrayBar: FC<Props> = ({className}) => {

  return (
    <div className={`bg-secondary-subtle mt-1 ${className}`} style={{ height: '8px', borderTop: '1px solid #a6a3a3', borderBottom: '1px solid #a6a3a3' }}></div>
  )
}

