import { Spinner } from '../../components/ui/Spinner'
import toast from 'react-hot-toast'
import { ConfirmationToast } from '../../components/ConfirmationToast'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate, useParams } from 'react-router-dom'
import { FrpDelegate } from '../../models/frp'
import { useDeleteFrpDelegateMutation, useGetFrpDelegatesQuery, useGetFrpInfoTypesQuery } from '../../hooks/frpHooks'
import { createInfoToastAuto } from '../../services/queryClient'
import { FormatMonthAbbrevDayYear } from '../../utils/dateConverter'
import { useState } from 'react'

export const FrpDelegatesDisplay = () => {
    const navigate = useNavigate()
    const params = useParams()
    const isMobile = window.innerWidth < 768
    const frpStudentId = params.frpStudentId ? Number(params.frpStudentId) : 0

    const [showPassphrase, setShowPassphrase] = useState<number>()

    const deleteFrpDelegate = useDeleteFrpDelegateMutation()
    const getFrpDelegatesQuery = useGetFrpDelegatesQuery(frpStudentId)
    const delegates = getFrpDelegatesQuery.data
    const getFrpInfoTypesQuery = useGetFrpInfoTypesQuery()
    const infoTypes = getFrpInfoTypesQuery.data

    if (getFrpDelegatesQuery.isLoading)
        return <Spinner />;
    if (getFrpDelegatesQuery.isError)
        return <h3>Error getting Delegates</h3>;

    const sendToEditDelegate = (frpDelegateId: number) => {
        if (frpDelegateId > 0) navigate(`/frp/addDelegate/${frpDelegateId}/${frpStudentId}`) 
    }

    const deleteConfirm = (frpDelegateId: number) => {
        toast((t) => (
            <ConfirmationToast
                toastId={t.id}
                message={`Are you sure you want to delete this delegate?`}
                confirmHandler={() => {
                    deleteFrpDelegate.mutate({frpDelegateId})
                    createInfoToastAuto(`The delegate has been deleted.`)
                    toast.dismiss(t.id);
                }} />
        ), { duration: Infinity })
    }

    const showInfoTypes =  (frpInfoTypes: number[]) => {
        return (
            <div>
                {frpInfoTypes?.map((frpInfoTypeId: number, index: number) => {
                    const frpInfoType = infoTypes?.filter(m => m.frpInfoTypeId === frpInfoTypeId)[0]
                    if (frpInfoType) {
                        return <div key={index}>{frpInfoType.short}</div>
                    }
                    return null
                })}
            </div>
        )
    }

    const isExpired = (expireDate: string | undefined) => {
        if (!expireDate) return false
        const today = new Date()
        const expire = new Date(expireDate)
        return expire < today
    }

    const expireClass = (expireDate: string | undefined) => {
        if (isExpired(expireDate)) return ' opacity-50 fst-italic '
    }

    return (
        <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
            <div className="justify-content-center">
                <h5 className="border bg-bold text-white rounded p-1">FERPA Delegates</h5>
                {delegates && delegates.length > 10 &&
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addDelegate")}>
                        Add a Delegate
                    </button>
                }
                <div>
                    {isMobile && 
                        <div>
                            <hr/>
                            {delegates?.map((m: FrpDelegate, index: number) => 
                                <div key={index}>
                                    <TextDisplay label="Delegate" text={`${m.firstName} ${m.lastName}`} className={expireClass(m.expireDate)} />
                                    <TextDisplay label="Relation" text={m.frpRelationName || ''} className={expireClass(m.expireDate)} />
                                    {!isExpired(m.expireDate) &&
                                        <div className="d-flex" onMouseOver={() => setShowPassphrase(m.frpDelegateId)} onMouseOut={() => setShowPassphrase(0)}>
                                            <TextDisplay label="Passphrase" text='' jsx={showPassphrase === m.frpDelegateId ? m.passphrase : <div className="d-flex flex-nowrap"><i className="bi bi-eye-slash"/>***************</div>} />
                                        </div>
                                    }
                                    <TextDisplay label="Cell number" text={m.cellNumber || 'none'} className={expireClass(m.expireDate)} />
                                    <TextDisplay label="Email address" text={m.emailAddress || 'none'} className={expireClass(m.expireDate)} />
                                    <TextDisplay label="Expire date" text={FormatMonthAbbrevDayYear(m.expireDate) || 'none'} />
                                    <TextDisplay label="Expire date" text='' jsx={m.frpInfoTypes && showInfoTypes(m.frpInfoTypes)} className={expireClass(m.expireDate)} />
                                    <div className="d-flex flex-nowrap mt-4">
                                        <div className="ms-4 btn btn-outline-bold" title="Edit this delegate" onClick={() => sendToEditDelegate(m.frpDelegateId || 0)}>
                                            <i className="bi bi-pencil d-block"></i>
                                        </div>
                                        <div className="ms-4 btn btn-outline-danger" title="Delete this delegate" onClick={() => deleteConfirm(m.frpDelegateId || 0)}>
                                            <i className="bi bi-trash d-block"></i>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            )}
                        </div>
                    }
                    {!isMobile && 
                        <div className="table-responsive w-100">
                            <table className="table table-striped w-sm-100 w-md-80 w-lg-50">
                                <thead>
                                    <tr>
                                        <th className="me-3 pe-5">Delegate</th>
                                        <th className="me-3 pe-5">Relation</th>
                                        <th className="me-3 pe-5">Passphrase</th>
                                        <th className="me-3 pe-5">Info access</th>
                                        <th className="me-3 pe-5">Email address</th>
                                        <th className="me-3 pe-5">Expire date</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {delegates?.map((m: FrpDelegate, index: number) => 
                                    <tr key={index}>
                                        <td className={expireClass(m.expireDate)}>{`${m.firstName} ${m.lastName}`}</td>
                                        <td className={expireClass(m.expireDate)}>{m.frpRelationName}</td>
                                        <td onMouseOver={() => setShowPassphrase(m.frpDelegateId)} onMouseOut={() => setShowPassphrase(0)}>
                                            {isExpired(m.expireDate) 
                                                ? <div className="opacity-50 fst-italic">EXPIRED</div> 
                                                : showPassphrase === m.frpDelegateId 
                                                    ? m.passphrase 
                                                    : <div className="d-flex flex-nowrap">
                                                        <i className="bi bi-eye-slash"/>***************
                                                      </div>
                                            }
                                        </td>
                                        <td className={expireClass(m.expireDate)}>{m.frpInfoTypes && showInfoTypes(m.frpInfoTypes)}</td>
                                        <td className={expireClass(m.expireDate)}>{m.emailAddress}</td>
                                        <td>{FormatMonthAbbrevDayYear(m.expireDate)}</td>
                                        <td>
                                            <div className="d-flex flex-nowrap">
                                                <div className="btn btn-outline-bold me-2" onClick={() => sendToEditDelegate(m.frpDelegateId || 0)}>
                                                    <i className="bi bi-pencil"></i>
                                                </div>
                                                <div className="btn btn-outline-danger" onClick={() => deleteConfirm(m.frpDelegateId || 0)}>
                                                    <i className="bi bi-trash"></i>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    }
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addDelegate")}>
                        Add a Delegate
                    </button>
                    {!(delegates && delegates.length > 0) &&
                        <h5>There are not any delegates for this student</h5>
                    }
                </div>
                <div className="p-2 border rounded bg-light-subtle ms-2" style={{maxWidth: '800px'}}>
                    <div className="opacity-50">INSTRUCTIONS</div>
                    <div className="mb-4"><b className="text-bold">You can assign a person (referred to as a delegate) to have access to your specific information.</b> A few examples of a delegate are parents, brother, uncle, grandparent, friend, bishop and instructor. Delegates can speak with Snow College employees and request changes to your information on your behalf.</div>
                    <div className="mb-4">When working with Snow College, <b className="text-bold">your delegates must provide The Security Phrase you have assigned to them</b> - which can vary from your personal passphrase. Snow College employees will only share information you have authorized.</div>
                    <div className="mb-4"><b className="text-bold">IMPORTANT</b>: By adding a delegate, you authorize this person to perform all activities related to the categories of information selected in compliance with any access details you have specified.</div>
                </div>
            </div>
        </div>
    )
}

