export const isValidEmail = (email: string): boolean => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

export const isValidPhoneNumber = (phone: string) => {
  const pattern = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
  return pattern.test(phone)
};

export const formatPhone = (value: string) => {
  value = value.replace(/\D/g, ''); // Remove all non-digits
  if (value.length >= 3 && value.length <= 6) {
    value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
  } else if (value.length > 6) {
    value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
  }
  return value
}
