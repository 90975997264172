import { User } from "oidc-client-ts";
import { useState, useEffect, FC, ReactNode } from "react";
import { useAuth } from "react-oidc-context";
import { useTabFocus } from "./useTabFocus";

export const redirect_landing = "redirect_landing"

export const AuthRequired: FC<{ children: ReactNode }> = ({ children }) => {
  const auth = useAuth();
  const [authenticateNextLoad, setAuthenticateNextLoad] = useState(false);
  catchRefreshLoop();
  console.log("Refresh in: ", (auth.user?.expires_in ? auth.user.expires_in - 60 : "n/a"))
  console.log("Landing: ", localStorage.getItem(redirect_landing))
  const setCookie = (user: User) => {
    const token = user?.access_token;
    const expiresDate = new Date(0);
    expiresDate.setUTCSeconds(user.expires_at ?? 0);
    document.cookie = `jwt=${token}; expires=${expiresDate.toUTCString()}; samesite=Strict; path=/api;`;
  };
  if (auth.user) setCookie(auth.user);

  const reauthenticateIfSessionExpiredInBackground = () => {
    if (authenticateNextLoad) {
      const currentPath = window.location.pathname + window.location.search;
      localStorage.setItem(redirect_landing, currentPath);
      console.log("authenticateing after expired background session");
      setAuthenticateNextLoad(false);
      try {
        auth.signinRedirect().catch(e => {
          console.log("error on sign in redirect after background session expired", e)
          auth.signinPopup().catch(() => {
            console.log(
              "Signin popup on returned error after background session expired",
            );
            setAuthenticateNextLoad(true);
          });
        });
      } catch (e) {
        console.log("other error catch after background session expired", e);
      }
    }
  };
  useTabFocus({ onFocus: reauthenticateIfSessionExpiredInBackground });

  useEffect(() => {
    if (
      !auth.activeNavigator &&
      !auth.isLoading &&
      !auth.error &&
      !auth.isAuthenticated
    ) {
      const currentPath = window.location.pathname + window.location.search;
      localStorage.setItem(redirect_landing, currentPath);
      auth.signinRedirect();
    }
  });

  if (auth.error && auth.error.message === "Failed to fetch") {
    const currentPath = window.location.pathname + window.location.search;
    localStorage.setItem(redirect_landing, currentPath);
    auth.signinRedirect();
  }

  const loginHandler = () => {
    localStorage.setItem(redirect_landing, "/");
    auth.signinRedirect();
  }

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }
  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  if (auth.isAuthenticated) {
    return <>{children}</>;
  }
  return <button onClick={loginHandler}>Log in</button>;
}

var refreshHistory: number[] = []
function catchRefreshLoop() {
  if (refreshHistory.length >= 10) {
    refreshHistory.pop();
    refreshHistory = [Date.now(), ...refreshHistory];
  }
  else {
    refreshHistory = [Date.now(), ...refreshHistory];
  }
  if (refreshHistory[0] - refreshHistory[9] < 1000) {
    throw Error("Page rendered too many times.");
  }
}
