import { FC, useEffect, useState } from 'react'
import { FrpSearchOnly, FrpSessionSearch } from '../../models/frp'
import { useGetFrpSessionWorkerUsernamesQuery } from '../../hooks/frpHooks';

interface Props {
  setFilterCount: (c: number) => void;
  setSearching: (c: boolean) => void;
  setSessionSearch: (c: FrpSessionSearch) => void;
  frpWorkerUsername?: string;
  pagePointer: number;
  queryTake: number;
}

export const FrpFilterSessionsDisplay: FC<Props> = ({setFilterCount, setSearching, setSessionSearch, frpWorkerUsername, pagePointer, queryTake}) => {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    const isMobile = window.innerWidth < 768

    const workerUsernamesQuery = useGetFrpSessionWorkerUsernamesQuery()
    const workerUsernames = workerUsernamesQuery.data
    // We could also change the other search (student session search) to use a post and a data-set instead - just like this page
    
    const [searchOnlyType, setSearchOnlyType] = useState<FrpSearchOnly | null>()
    const [searchDateFrom, setSearchDateFrom] = useState<string>(todayDate.toISOString())
    const [searchDateTo, setSearchDateTo] = useState<string>()
    const [searchLastName, setSearchLastName] = useState<string>('')
    const [searchFirstName, setSearchFirstName] = useState<string>('')
    const [searchBadgerid, setSearchBadgerid] = useState<string>('')
    const [searchUsername, setSearchUsername] = useState<string>('')
    const [searchWorkerUsername, setSearchWorkerUsername] = useState<string>(frpWorkerUsername || '')
    const [isInit, setIsInit] = useState<boolean>(false)

    useEffect(() => {
        if (!isInit) {
            search()
            setIsInit(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const search = () => {
        setSearching(true)
        const sessionSearch: FrpSessionSearch = {
            pagePointer,
            queryTake
        }
        let newFilterCount = 0
        if (searchOnlyType === FrpSearchOnly.Unverified) sessionSearch.unverifiedOnly = true
        if (searchOnlyType === FrpSearchOnly.Failed) sessionSearch.failedOnly = true
        if (searchOnlyType === FrpSearchOnly.Passed) sessionSearch.passedOnly = true
        if (searchDateFrom) sessionSearch.dateFromSearch = searchDateFrom?.toString().split('T')[0]
        if (searchDateTo) sessionSearch.dateToSearch = searchDateTo?.toString().split('T')[0]
        sessionSearch.firstNameSearch = "%" + searchFirstName + "%"
        sessionSearch.lastNameSearch = "%" + searchLastName + "%"
        sessionSearch.badgeridSearch = "%" + searchBadgerid + "%"
        sessionSearch.usernameSearch = "%" + searchUsername + "%"
        sessionSearch.workerUsernameSearch = searchWorkerUsername
        setFilterCount(newFilterCount)            
        setSessionSearch(sessionSearch)
    }

    const handleSearchOnlyType = (searchOnly: FrpSearchOnly) => {
        if (searchOnly === searchOnlyType) {
            setSearchOnlyType(FrpSearchOnly.None)
        } else {
            setSearchOnlyType(searchOnly)
        }
    }

    const clearFilters = () => {
        setSearchOnlyType(null)
        setSearchDateFrom('')
        setSearchDateTo('')
        setSearchFirstName('')
        setSearchLastName('')
        setSearchBadgerid('')
        setSearchUsername('')
        setSearchWorkerUsername('')
    }

    return (
        <div className="bg-secondary-subtle pt-2 rounded">
            <div className="d-flex flex-wrap ms-3 text-left">
                <div className="mt-1">
                    <div className="form-check d-flex">
                        <input 
                            className="form-check-input fs-bold my-auto mt-1" 
                            type="checkbox" 
                            id="unverifiedOnly"
                            name="unverifiedOnly"
                            checked={searchOnlyType === FrpSearchOnly.Unverified}
                            onChange={() => handleSearchOnlyType(FrpSearchOnly.Unverified)}
                        />
                        <label className="form-check-label small btn m-n1 p-0 mb-2 my-auto" htmlFor="unverifiedOnly">
                            Only unverified
                        </label>
                    </div>
                    <div className="form-check d-flex">
                        <input 
                            className="form-check-input fs-bold my-auto mt-1" 
                            type="checkbox" 
                            id="failedOnly"
                            name="failedOnly"
                            checked={searchOnlyType === FrpSearchOnly.Failed}
                            onChange={() => handleSearchOnlyType(FrpSearchOnly.Failed)}
                        />
                        <label className="form-check-label small btn m-n1 p-0 mb-2 my-auto" htmlFor="failedOnly">
                            Only failed
                        </label>
                    </div>
                    <div className="form-check d-flex">
                        <input 
                            className="form-check-input fs-bold my-auto mt-1" 
                            type="checkbox" 
                            id="passedOnly"
                            name="passedOnly"
                            checked={searchOnlyType === FrpSearchOnly.Passed}
                            onChange={() => handleSearchOnlyType(FrpSearchOnly.Passed)}
                        />
                        <label className="form-check-label small btn m-n1 p-0 mb-2 my-auto" htmlFor="passedOnly">
                            Only passed
                        </label>
                    </div>
                </div>
                <div>
                    <div className="form-check my-auto mt-n2">
                        <label className="form-check-label small my-auto" htmlFor="futureDates">
                            Date From
                        </label>
                        <input 
                            type="date"
                            className="form-control form-control-sm"
                            style={{width: 'auto'}}
                            name="searchDateFrom"
                            value={searchDateFrom?.toString().split('T')[0]}
                            onChange={(event) => setSearchDateFrom(event?.target.value)}
                        />
                    </div>
                    <div className="form-check me-3 my-auto mb-2">
                        <label className="form-check-label small" htmlFor="futureDates">
                            Date To
                        </label>
                        <input 
                            type="date"
                            style={{width: 'auto'}}
                            className="form-control form-control-sm"
                            name="searchDateTo"
                            value={searchDateTo?.toString().split('T')[0]}
                            onChange={(event) => setSearchDateTo(event?.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-12 col-md-auto my-auto mt-n2">
                            <label htmlFor="nameSearch" className="form-label small my-auto me-2 pe-2">First Name</label>
                            <input 
                                type="text"
                                className="form-control form-control-sm"
                                id="nameSearch"
                                value={searchFirstName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchFirstName(event.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-auto my-auto mt-n2">
                            <label htmlFor="lastNameSearch" className="form-label small my-auto me-2 pe-2">Last Name</label>
                            <input 
                                type="text"
                                className="form-control form-control-sm"
                                id="lastNameSearch"
                                value={searchLastName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchLastName(event.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-auto my-auto mt-n2">
                            <label htmlFor="badgeridSearch" className="form-label small my-auto me-2 pe-2">Badger ID</label>
                            <input 
                                type="text"
                                className="form-control form-control-sm"
                                id="badgeridSearch"
                                value={searchBadgerid}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchBadgerid(event.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-auto my-auto mt-n2">
                            <label htmlFor="usernameSearch" className="form-label small my-auto me-2 pe-2">Username</label>
                            <input 
                                type="text"
                                className="form-control form-control-sm"
                                id="usernameSearch"
                                value={searchUsername}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchUsername(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className={isMobile ? '' : 'd-flex'}>
                        <div className="mb-3 me-3 my-auto" style={{width: `${isMobile ? '120px' : 'auto'}`}}>
                            <label htmlFor="workerSearch" className="form-label small my-auto me-2 pe-2">Facilitator</label>
                            <select
                                style={{width: 'auto'}} 
                                className="form-select form-select-sm"
                                value={searchWorkerUsername}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setSearchWorkerUsername(event.target.value)}
                            >
                                <option key={0} value={''}>- -</option>
                                {workerUsernames?.map(m => 
                                    <option key={String(m.workerUsername)} value={String(m.workerUsername)}>
                                        {m.workerUsername}
                                    </option>
                                )}            
                            </select>
                        </div>
                        <button className="btn btn-outline-bold my-auto" onClick={search} style={{height: '38px'}}>
                            Search
                        </button>
                        <button className="btn btn-text text-bold ms-4" onClick={clearFilters}>
                            clear filters
                        </button>
                        <div className="me-5 opacity-50 small mt-5">Partial text searches are acceptable.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

