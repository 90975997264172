import { FC, useEffect, useState } from 'react'
import { FrpFacilitatorSummary, FrpSummarySearch } from '../../models/frp'
import { useFrpFacilitatorSummaryMutation } from '../../hooks/frpHooks';

interface Props {
    setFilterCount: (c: number) => void,
    setSearching: (c: boolean) => void,
    setFilteredSessions: (c: FrpFacilitatorSummary[]) => void,
}

export const FrpFilterFacilitatorSummaryDisplay: FC<Props> = ({setFilterCount, setSearching, setFilteredSessions}) => {
    const summaryQuery = useFrpFacilitatorSummaryMutation()
    
    const [searchDateFrom, setSearchDateFrom] = useState<string>()
    const [searchDateTo, setSearchDateTo] = useState<string>()
    const [searchLastName, setSearchLastName] = useState<string>('')
    const [searchFirstName, setSearchFirstName] = useState<string>('')
    const [isInit, setIsInit] = useState<boolean>(false)

    useEffect(() => {
        if (!isInit) {
            search()
            setIsInit(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const search = () => {
        setSearching(true)
        const summarySearch: FrpSummarySearch = {}
        let newFilterCount = 0
        if (searchDateFrom) summarySearch.dateFromSearch = searchDateFrom?.toString().split('T')[0]
        if (searchDateTo) summarySearch.dateToSearch = searchDateTo?.toString().split('T')[0]
        summarySearch.firstNameSearch = "%" + searchFirstName + "%"
        summarySearch.lastNameSearch = "%" + searchLastName + "%"
        setFilterCount(newFilterCount)            

        summaryQuery.mutate({
            summarySearch,
            setFilteredSessions,
            setSearching
        })
    }

    const clearFilters = () => {
        setSearchDateFrom('')
        setSearchDateTo('')
        setSearchFirstName('')
        setSearchLastName('')
    }

return (
        <div className="bg-secondary-subtle pt-2 rounded">
            <div className="d-flex flex-wrap ms-3 text-left">
                <div>
                    <div className="form-check my-auto mt-n2">
                        <label className="form-check-label small my-auto" htmlFor="futureDates">
                            Date From
                        </label>
                        <input 
                            type="date"
                            className="form-control form-control-sm"
                            style={{width: 'auto'}}
                            name="searchDateFrom"
                            value={searchDateFrom?.toString().split('T')[0]}
                            onChange={(event) => setSearchDateFrom(event?.target.value)}
                        />
                    </div>
                    <div className="form-check me-3 my-auto mb-2">
                        <label className="form-check-label small" htmlFor="futureDates">
                            Date To
                        </label>
                        <input 
                            type="date"
                            style={{width: 'auto'}}
                            className="form-control form-control-sm"
                            name="searchDateTo"
                            value={searchDateTo?.toString().split('T')[0]}
                            onChange={(event) => setSearchDateTo(event?.target.value)}
                        />
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-12 col-md-auto my-auto mt-n2">
                            <label htmlFor="nameSearch" className="form-label small my-auto me-2 pe-2">First Name</label>
                            <input 
                                type="text"
                                className="form-control form-control-sm"
                                id="nameSearch"
                                value={searchFirstName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchFirstName(event.target.value)}
                            />
                        </div>
                        <div className="col-12 col-md-auto my-auto mt-n2">
                            <label htmlFor="lastNameSearch" className="form-label small my-auto me-2 pe-2">Last Name</label>
                            <input 
                                type="text"
                                className="form-control form-control-sm"
                                id="lastNameSearch"
                                value={searchLastName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchLastName(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        <button className="btn btn-outline-bold my-auto" onClick={search} style={{height: '38px'}}>
                            Search
                        </button>
                        <button className="btn btn-text text-bold ms-4" onClick={clearFilters}>
                            clear filters
                        </button>
                        <div className="me-5 opacity-50 small mt-5">Partial text searches are acceptable.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

