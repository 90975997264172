import { FC, useEffect, useState } from 'react'
import { FrpStudent } from '../../models/frp'
import { useSearchFrpStudentsMutation } from '../../hooks/frpHooks'
import { createInfoToastAuto } from '../../services/queryClient'

interface Props {
    setFilterCount: (c: number) => void,
    setFilteredStudents: (c: FrpStudent[]) => void,
    setSearching: (c: boolean) => void,
    students?: FrpStudent[]
    talkingTo: string,
    setTalkingTo: (c: string) => void,
}

export const FrpFilterSessionSearch: FC<Props> = ({setFilterCount, setFilteredStudents, setSearching, students}) => {
    const [searchLastName, setSearchLastName] = useState<string>('')
    const [searchFirstName, setSearchFirstName] = useState<string>('')
    const [searchBadgerid, setSearchBadgerid] = useState<string>('')
    const [searchUsername, setSearchUsername] = useState<string>('')

    const searchFrpStudents = useSearchFrpStudentsMutation()

    const search = () => {
        if (!searchLastName && !searchFirstName && !searchBadgerid && !searchUsername) {
            createInfoToastAuto(`Please enter in one or more search options before searching`)
        } else {
            setSearching(true)
            searchFrpStudents.mutate({
                firstNameSearch: searchFirstName,
                lastNameSearch: searchLastName,
                badgeridSearch: searchBadgerid,
                usernameSearch: searchUsername,
                setFilteredStudents,
                setSearching
            })
        }
    }

    useEffect(() => {
        let newFilterCount = 0
        let newFiltered = students ? [...students] : []
        if (searchFirstName) {newFilterCount++; newFiltered = newFiltered?.filter(m => m.firstName && m.firstName.toLowerCase().indexOf(searchFirstName.toLowerCase()) > -1)}
        if (searchLastName) {newFilterCount++; newFiltered = newFiltered?.filter(m => m.lastName && m.lastName.toLowerCase().indexOf(searchLastName.toLowerCase()) > -1)}
        if (searchBadgerid) {newFilterCount++; newFiltered = newFiltered?.filter(m => m.badgerid && m.badgerid.indexOf(searchBadgerid) > -1)}
        if (searchUsername) {newFilterCount++; newFiltered = newFiltered?.filter(m => m.username && m.username.indexOf(searchUsername) > -1)}
        setFilteredStudents(newFiltered)
        setFilterCount(newFilterCount)
    }, [students, searchFirstName, searchLastName, searchBadgerid, searchUsername, setFilterCount, setFilteredStudents])

    const handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            search();
        }
    }

    const clearFilters = () => {
        setSearchFirstName('')
        setSearchLastName('')
        setSearchBadgerid('')
        setSearchUsername('')
    }

    return (
        <div className="container-fluid bg-secondary-subtle pt-2 rounded pb-4">
            <div className="row g-2">
                <div className="col-12 col-md-auto">
                    <label htmlFor="nameSearch" className="form-label small">First Name</label>
                    <input 
                        type="text"
                        className="form-control form-control-sm"
                        id="nameSearch"
                        value={searchFirstName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchFirstName(event.target.value)}
                        onKeyDown={handleEnterKey}                        
                    />
                </div>
                <div className="col-12 col-md-auto">
                    <label htmlFor="lastNameSearch" className="form-label small">Last Name</label>
                    <input 
                        type="text"
                        className="form-control form-control-sm"
                        id="lastNameSearch"
                        value={searchLastName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchLastName(event.target.value)}
                        onKeyDown={handleEnterKey}                        
                    />
                </div>
                <div className="col-12 col-md-auto">
                    <label htmlFor="badgeridSearch" className="form-label small">Badger ID</label>
                    <input 
                        type="text"
                        className="form-control form-control-sm"
                        id="badgeridSearch"
                        value={searchBadgerid}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchBadgerid(event.target.value)}
                        onKeyDown={handleEnterKey}                        
                    />
                </div>
                <div className="col-12 col-md-auto">
                    <label htmlFor="usernameSearch" className="form-label small">Username</label>
                    <input 
                        type="text"
                        className="form-control form-control-sm"
                        id="usernameSearch"
                        value={searchUsername}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchUsername(event.target.value)}
                        onKeyDown={handleEnterKey}                        
                    />
                </div>
                <div className="col-12 col-md-auto d-flex align-items-end">
                    <button className="btn btn-outline-bold" onClick={search}>
                        Search
                    </button>
                </div>
                <div className="col-12 col-md-auto d-flex align-items-end">
                    <button className="btn btn-text btn-link text-bold ms-4" onClick={clearFilters}>clear filters</button>
                </div>
                <div className="me-5 opacity-50 small">Partial text searches are acceptable. Press the enter key in any field to search.</div>
            </div>
        </div>
    )
}
