import { FC } from "react"
import { FrpInfoType } from "../../models/frp"

interface Props {
  isMobile: boolean,
  showInfoAccess?: number[]
  setShowInfoAccess: (c: number[]) => void,
  infoTypes?: FrpInfoType[],
}

export const FrpSessionActiveInfoTypeDisplay: FC<Props> = ({isMobile, showInfoAccess, setShowInfoAccess, infoTypes}) => {

  return (
    <div style={{maxWidth: isMobile ? '90vw' : '600px'}} className="mt-3 text-center" id="divInfoTypes">
      <div className="d-flex justify-content-end w-100">
          <button className="btn btn-text btn-link text-secondary" onClick={() => setShowInfoAccess([])}>close</button>
      </div>
      <div className="border rounded bg-white text-center text-black mb-3">ACCESS INFORMATION TYPES</div>
      {infoTypes?.map((m: FrpInfoType) => {
        const isSelected = showInfoAccess && showInfoAccess?.indexOf(m.frpInfoTypeId) > -1
        return (
          <div className={`border rounded ${isSelected ? 'bg-dark-subtle' : 'bg-light-subtle'} p-2 mb-2`} key={`info${m.frpInfoTypeId}`}>
            <div className="d-flex"> 
              <div className="form-check ms-2 me-3">
                <input
                    className="form-check-input mt-1 border-bold"
                    type="checkbox"
                    style={{fontSize: '24px'}}
                    checked={isSelected}
                    disabled={true}
                    onChange={() => {}}
                />
              </div>
              <div>
                <div>
                    <b>{m.title}</b>
                </div>
                <div>
                    {m.description}
                </div>
              </div>
          </div>
        </div>
      )
      })}
    </div>
	)
}

