import { FrpAdminLanding } from "./FrpAdminLanding"
import { useIsFrpAdminQuery, useIsFrpWorkerQuery } from "../../hooks/frpHooks"
import { FrpStudentLanding } from "./FrpStudentLanding"
import { FrpWorkerLanding } from "./FrpWorkerLanding"

export const FrpCard = () => {

  const isFrpAdminQuery = useIsFrpAdminQuery()
  const isFrpAdmin = isFrpAdminQuery.data
  const isFrpWorkerQuery = useIsFrpWorkerQuery()
  const isFrpWorker = isFrpWorkerQuery.data

  const showAdminLines = () => {
    if (!isFrpAdmin) return null
    return (
      <div className="mt-2">
        You are a Ferpa Administrator
        <FrpAdminLanding />
      </div>
    )
  }

  const showWorkerLines = () => {
    if (!isFrpWorker) return null
    return (
      <div>
        You are a Ferpa Facilitator
        <FrpWorkerLanding />
      </div>
    )
  }

  const showStudentLines = () => {
    // if (!isStudent) return null
    return (
      <div>
        <FrpStudentLanding />
      </div>
    )
  }

  return (
    <div>
      {showStudentLines()}
      {showWorkerLines()}
      {showAdminLines()}
    </div>
  )
}

