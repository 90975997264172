import { Spinner } from '../../components/ui/Spinner'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate, useParams } from 'react-router-dom'
import { FrpDelegate } from '../../models/frp'
import { useChooseSessionDelegateMutation, useGetFrpInfoTypesQuery, useGetFrpSessionActiveQuery, useUpdateFrpSessionTalkingToMutation } from '../../hooks/frpHooks'
import { FormatMonthAbbrevDayYear } from '../../utils/dateConverter'
import { useEffect, useState } from 'react'
import { FrpSessionActiveEmailCode } from '../../components/ferpa/FrpSessionActiveEmailCode'
import { FrpSessionActiveClose } from '../../components/ferpa/FrpSessionActiveClose'
import { FrpSessionActiveInfoTypeDisplay } from '../../components/ferpa/FrpSessionActiveInfoTypeDisplay'
import { FrpSessionPassOrFail } from '../../components/ferpa/FrpSessionPassOrFail'
import { FrpSessionInternalComments } from '../../components/ferpa/FrpSessionInternalComments'
import { FrpSessionQuestions } from '../../components/ferpa/FrpSessionQuestions'
import { createInfoToastAuto } from '../../services/queryClient'

export const FrpSessionActive = () => {
    const navigate = useNavigate()
    const params = useParams()
    const isMobile = window.innerWidth < 768
    const frpSessionId = params.frpSessionId ? Number(params.frpSessionId) :  0

    const [showPassphrase, setShowPassphrase] = useState<number>()
    const [showStudentPassphrase, setShowStudentPassphrase] = useState<boolean>()
    const [showInfoAccess, setShowInfoAccess] = useState<number[]>()
    const [talkingTo, setTalkingTo] = useState<string>('')
    const [emailCode, setEmailCode] = useState<number>()
    const [timeLeft, setTimeLeft] = useState<string>('');
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

    const getFrpSessionActiveQuery = useGetFrpSessionActiveQuery(frpSessionId)
    const session = getFrpSessionActiveQuery.data
    const updateTalkingTo = useUpdateFrpSessionTalkingToMutation(frpSessionId)
    const chooseSessionDelegate = useChooseSessionDelegateMutation(frpSessionId)
    const getFrpInfoTypesQuery = useGetFrpInfoTypesQuery()
    const infoTypes = getFrpInfoTypesQuery.data

    useEffect(() => {
        if (session && !session.talkingTo) {
            let hasActiveDelegates = false
            session?.delegates?.forEach(m => {
                if (!isExpired(m.expireDate)) {
                    hasActiveDelegates = true
                }
            })

            const newTalkingTo = session.talkingTo ? session.talkingTo : !hasActiveDelegates ? 'Student' : ''
            setTalkingTo(newTalkingTo)
            if (newTalkingTo) {
                updateTalkingTo.mutate({talkingTo: newTalkingTo})
                session.talkingTo = newTalkingTo
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session])

    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId)
                setTimeLeft('00:00:00') 
            }
        }
    }, [intervalId])

    if (getFrpSessionActiveQuery.isLoading)
        return <Spinner />;
    if (getFrpSessionActiveQuery.isError)
        return <h3>Error getting Session</h3>;
    if (!session) <h3>The session could not be found</h3>

    const showInfoTypes =  (frpInfoTypes: number[]) => {
        return (
            <div className="text-left ms-n2 ps-n2">
                {frpInfoTypes?.map((frpInfoTypeId: number) => {
                    const frpInfoType = infoTypes?.filter(m => m.frpInfoTypeId === frpInfoTypeId)[0]
                    if (frpInfoType) {
                        return <div key={`short${frpInfoTypeId}`}>{frpInfoType.short}</div>
                    }
                    return null
                })}
            </div>
        )
    }

    const showWhoVerifying = () => {
        return !(session?.delegates && session?.delegates.length > 0) ? null : (
            <div className={`d-flex mb-3 ${isMobile ? 'flex-column' : 'flex-row'}`}>
                <div className="me-2 my-auto">Who are you verifying?</div>
                <div className={`d-flex mt-n2 my-auto ${isMobile ? 'ms-3' : ''}`}>
                    <div className="form-check mt-2 d-flex align-items-center">
                        <input 
                            className="form-check-input fs-bold my-auto" 
                            type="radio" 
                            id="studentCheckbox"
                            name="studentCheckbox"
                            checked={talkingTo === 'Student'}
                            disabled={session?.isClosed}
                            onChange={() => handleTalkingTo('Student')}
                        />
                        <label className="form-check-label small btn m-n1 p-0" htmlFor="studentCheckbox">
                            Student
                        </label>
                    </div>
                    <div className="form-check mt-2 ms-4 d-flex align-items-center">
                        <input 
                            className="form-check-input fs-bold my-auto" 
                            type="radio" 
                            id="delegateCheckbox"
                            name="delegateCheckbox"
                            checked={talkingTo === 'Delegate'}
                            disabled={session?.isClosed}
                            onChange={() => noActiveDelegate() ? createInfoToastAuto("There are not any active delegates.") : handleTalkingTo('Delegate')}
                        />
                        <label className="form-check-label small btn m-n1 p-0" htmlFor="delegateCheckbox">
                            Delegate
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    const noActiveDelegate = () => {
        let hasActiveDelegates = false
        session?.delegates?.forEach(m => {
            if (!isExpired(m.expireDate)) {
                hasActiveDelegates = true
            }
        })
        return !hasActiveDelegates
    }

    const handleTalkingTo = (talkingTo: string) => {
        console.log('handle talkingTo', )
        setTalkingTo(talkingTo)
        updateTalkingTo.mutate({talkingTo})
        if (intervalId) {
            clearInterval(intervalId)
            setTimeLeft('00:00:00')
        }
        // if (talkingTo === "Delegate") {
            if (session?.delegates?.length === 1) {
                chooseSessionDelegate.mutate({frpDelegateId: session?.delegates[0].frpDelegateId})
                setShowInfoAccess(session?.delegates[0].frpInfoTypes)
            }
        // }
    }

    const isExpired = (expireDate: string | undefined) => {
        if (!expireDate) return false
        const today = new Date()
        const expire = new Date(expireDate)
        return expire < today
    }

    const expireClass = (expireDate: string | undefined) => {
        if (isExpired(expireDate)) return ' opacity-50 fst-italic '
    }

    const showDelegateButtons = (frpDelegateId: number, frpInfoTypes: number[], expireDate: string | undefined) => {
        if (talkingTo === 'Delegate' && !session?.isClosed) {
            if (isExpired(expireDate)) {
                return <div>EXPIRED</div>

            } else if (frpDelegateId === session?.frpDelegateId) {
                if (!showInfoAccess) setShowInfoAccess(frpInfoTypes)
                return <div>SELECTED</div>
            } else {
                return (
                    <div className="btn btn-outline-bold me-2" 
                         onClick={() => {
                            chooseSessionDelegate.mutate({frpDelegateId: frpDelegateId || 0})
                            setShowInfoAccess(frpInfoTypes)
                            if (intervalId) {
                                clearInterval(intervalId)
                                setTimeLeft('00:00:00')
                            }
                         }}>
                        select
                    </div>
                )
            }
        }
    }

    const handleInfoTypeClick = (delegate: FrpDelegate) => {
        if (delegate.frpDelegateId === session?.frpDelegateId) {
            setShowInfoAccess(delegate.frpInfoTypes)
            setTimeout(() => document.getElementById('divInfoTypes')?.scrollIntoView({ behavior: 'smooth' }), 300)
        }
    }

    const isDelegate = (frpDelegateId: number) => {
        return frpDelegateId === session?.frpDelegateId
    }

    const setEmailAddress = (studentUsername: string) => {
        if (!studentUsername) {
            return ''
        } else if (studentUsername.indexOf('@') > -1) {
            return studentUsername
        } else {
            return studentUsername + '@students.snow.edu'
        }
    }

    const cleanUsername = (username: string) => {
        if (username && username.indexOf('@') > -1) {
            return username.substring(0, username.indexOf('@'))
        } 
        return username
    }

    return (
        <div style={{minWidth: isMobile ? 'auto' : '1000px', marginBottom: '100px'}} className="mx-auto">
            <h5 className="border bg-bold text-white rounded p-1">FERPA Session</h5>
            {isMobile && 
                <div>
                    <div>
                        {session &&
                            <div>
                                <TextDisplay label="Student" text={`${session.studentFirstName} ${session.studentLastName}`}/>
                                <div className="d-flex" onMouseOver={() => setShowStudentPassphrase(true)} 
                                                        onClick={() => setShowStudentPassphrase(true)} 
                                                        onMouseOut={() => setShowStudentPassphrase(false)}>
                                    <TextDisplay label="Passphrase" text='' jsx={session.studentPassphrase 
                                        ? showStudentPassphrase && !session?.isClosed 
                                            ? session.studentPassphrase 
                                            : <div className="d-flex flex-nowrap"><i className="bi bi-eye-slash"/>***************</div>
                                        : <div><i>none</i></div>} />
                                </div>
                                <TextDisplay label="Sessions" text='' jsx={<div onClick={() => navigate(`/frp/sessions/student/${session?.frpStudentId}`)}>{session.otherSessionsCount || 0}</div>} />
                                <TextDisplay label="Badgerid" text={session?.studentBadgerid || ''}/>
                                <TextDisplay label="Username" text={cleanUsername(session?.studentUsername || '')}/>
                                <TextDisplay label="Email address" text={session.studentEmailAddress || ''}/>
                                <hr/>
                            </div>
                        }
                    </div>
                    {session?.isOffDirectoryInfo && <div className="text-warning">CONFIDENTIAL</div>}                    
                    {showWhoVerifying()}
                    <div>
                        <hr/>
                        {session?.delegates?.map((m: FrpDelegate, index: number) => 
                            <div key={index}>
                                {showDelegateButtons(m.frpDelegateId, m.frpInfoTypes || [], m.expireDate)}
                                <TextDisplay label="Delegate" text={`${m.firstName} ${m.lastName}`} className={expireClass(m.expireDate)}/>
                                <TextDisplay label="Relation" text={m.frpRelationName || ''} className={expireClass(m.expireDate)} />
                                {isExpired(m.expireDate) 
                                    ?   <div className={expireClass(m.expireDate)}>EXPIRED</div>
                                    :    <div className="d-flex" onMouseOver={() => setShowPassphrase(m.frpDelegateId)} 
                                                                 onClick={() => setShowPassphrase(m.frpDelegateId)} 
                                                                 onMouseOut={() => setShowPassphrase(0)} style={{width: '175px'}}>
                                            <TextDisplay label="Passphrase" text="" jsx={showPassphrase === m.frpDelegateId && !session?.isClosed 
                                                ? m.passphrase 
                                                : <div className="d-flex flex-nowrap"><i className="bi bi-eye-slash"/>***************</div>} />
                                        </div>
                                }
                                <TextDisplay label="Information Access" text=''
                                    jsx={<div onClick={() => handleInfoTypeClick(m)} className={expireClass(m.expireDate)}>
                                            {m.frpInfoTypes && showInfoTypes(m.frpInfoTypes)}
                                        </div>
                                    } />
                                <TextDisplay label="Email address" text={m.emailAddress || 'none'} className={expireClass(m.expireDate)} />
                                <TextDisplay label="Expire date" text={FormatMonthAbbrevDayYear(m.expireDate) || 'none'} />
                                <hr/>
                            </div>
                        )}
                    </div>
                </div>
            }
            {!isMobile && 
                <div className="mx-auto wide-container w-100" style={{width: '100%'}}>
                    {session &&
                        <div className="d-flex flex-wrap">
                            <TextDisplay label="Student" text={`${session.studentFirstName} ${session.studentLastName}`} className="me-5"/>
                            <div className="d-flex" onMouseOver={() => setShowStudentPassphrase(true)} 
                                                    onClick={() => setShowStudentPassphrase(true)} 
                                                    onMouseOut={() => setShowStudentPassphrase(false)} style={{width: '175px'}}>
                                <TextDisplay label="Passphrase" text='' jsx={session.studentPassphrase 
                                    ? showStudentPassphrase && !session?.isClosed 
                                        ? session.studentPassphrase 
                                        : <div className="d-flex flex-nowrap"><i className="bi bi-eye-slash"/>***************</div>
                                    : <div><i>none</i></div>}  className="me-5" />
                            </div>
                            <TextDisplay label="Sessions" text='' jsx={<div onClick={() => navigate(`/frp/sessions/student/${session?.frpStudentId}`)}>{session.otherSessionsCount || 0}</div>}  className="me-5"/>
                            <TextDisplay label="Badgerid" text={session.studentBadgerid || ''} className="me-5"/>
                            <TextDisplay label="Username" text={cleanUsername(session.studentUsername || '')} className="me-5"/>
                            <TextDisplay label="Email address" text={setEmailAddress(session.studentUsername || '')} className="me-5"/>
                        </div>
                    }
                    <hr/>
                    {session?.isOffDirectoryInfo && <div className="text-warning">CONFIDENTIAL</div>}
                    {showWhoVerifying()}
                    {!(session?.delegates && session?.delegates.length > 0) &&
                        <div className="fst-italic">No delegates found</div>
                    }
                    {session?.delegates && session?.delegates.length > 0 &&
                        <div className="table-responsive w-100 border rounded bg-light-subtle p-2">
                            <table className="table w-sm-100 w-md-80 w-lg-50 bg-light-subtle">
                                <thead>
                                    <tr className="bg-light-subtle">
                                        <th className="bg-light-subtle"></th>
                                        <th className="me-3 pe-5 bg-light-subtle">Delegate</th>
                                        <th className="me-3 pe-5 bg-light-subtle">Relation</th>
                                        <th className="me-3 pe-5 bg-light-subtle">Passphrase</th>
                                        <th className="me-3 pe-5 bg-light-subtle">Info access</th>
                                        <th className="me-3 pe-5 bg-light-subtle">Email address</th>
                                        <th className="me-3 pe-5 bg-light-subtle">Expire date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {session?.delegates?.map((m: FrpDelegate, index: number) => 
                                    <tr key={index}>
                                        <td className={`text-center ${isDelegate(m.frpDelegateId) ? 'bg-white' : 'bg-light-subtle'}`}>{showDelegateButtons(m.frpDelegateId, m.frpInfoTypes || [], m.expireDate)}</td>
                                        <td className={isDelegate(m.frpDelegateId) ? 'bg-white text-dark' : `bg-light-subtle ${expireClass(m.expireDate)}`}>{`${m.firstName} ${m.lastName}`}</td>
                                        <td className={isDelegate(m.frpDelegateId) ? 'bg-white text-dark' : `bg-light-subtle ${expireClass(m.expireDate)}`}>{m.frpRelationName}</td>
                                        <td className={isDelegate(m.frpDelegateId) ? 'bg-white text-dark' : `bg-light-subtle ${expireClass(m.expireDate)}`}>
                                            {isExpired(m.expireDate) 
                                                ?   <div className={expireClass(m.expireDate)}>EXPIRED</div>
                                                :   <div onMouseOver={() => setShowPassphrase(m.frpDelegateId)} 
                                                         onClick={() => setShowPassphrase(m.frpDelegateId)} 
                                                         onMouseOut={() => setShowPassphrase(0)}>
                                                        {showPassphrase === m.frpDelegateId && !session?.isClosed ? m.passphrase : <div className="d-flex flex-nowrap"><i className="bi bi-eye-slash"/>***************</div>}
                                                    </div>
                                            }
                                        </td>
                                        <td className={`${isDelegate(m.frpDelegateId) ? 'bg-white text-dark' : `bg-light-subtle ${expireClass(m.expireDate)}`}`}>
                                            <div onClick={() => handleInfoTypeClick(m)} >
                                                {m.frpInfoTypes && showInfoTypes(m.frpInfoTypes)}
                                            </div>
                                        </td>
                                        <td className={isDelegate(m.frpDelegateId) ? 'bg-white text-dark' : `bg-light-subtle ${expireClass(m.expireDate)}`}>{m.emailAddress}</td>
                                        <td className={isDelegate(m.frpDelegateId) ? 'bg-white text-dark' : 'bg-light-subtle'}>{FormatMonthAbbrevDayYear(m.expireDate)}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
            <div className={`${isMobile ? '' : 'd-flex flex-wrap justify-content-between'}`}>
                <div>
                    {session?.studentUsername && 
                        <FrpSessionActiveEmailCode isMobile={isMobile}
                                                emailCode={emailCode}
                                                setEmailCode={setEmailCode}
                                                timeLeft={timeLeft}
                                                setTimeLeft={setTimeLeft}
                                                intervalId={intervalId} 
                                                setIntervalId={setIntervalId}
                                                session={session} 
                                                talkingTo={talkingTo} />
                    }
                    {!session?.studentUsername && 
                        <div className="fst-italic"><hr/>There is not an email address on file in order to use the Send Code feature.</div>
                    }
                    <hr/>
                    {talkingTo !== 'Delegate' && !session?.isClosed && 
                        <FrpSessionQuestions session={session} />
                    }
                    <FrpSessionInternalComments incomingFrpSessionId={frpSessionId} session={session} />
                    <FrpSessionPassOrFail incomingFrpSessionId={frpSessionId}
                                          intervalId={intervalId}
                                          isMobile={isMobile}
                                          talkingTo={talkingTo}
                                          session={session}
                                          setTimeLeft={setTimeLeft} />
                    <FrpSessionActiveClose incomingFrpSessionId={frpSessionId}
                                           session={session}
                                           intervalId={intervalId}
                                           setTimeLeft={setTimeLeft} />

                </div>
                {showInfoAccess && showInfoAccess.length > 0 && talkingTo === 'Delegate' && session?.frpDelegateId && 
                    <FrpSessionActiveInfoTypeDisplay isMobile={isMobile} showInfoAccess={showInfoAccess} setShowInfoAccess={setShowInfoAccess} infoTypes={infoTypes} />
                }
            </div>
        </div>
    )
    
}


