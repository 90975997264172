import { useState } from 'react'
import { Spinner } from '../../components/ui/Spinner'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { FrpVisitor } from '../../models/frp'
import { FormatMonthDayAtTimeNoSeconds } from '../../utils/dateConverter'
import { splitUsernameFirstLast } from '../../utils/stringFormat'
import { useGetFrpVisitorsQuery } from '../../hooks/frpHooks'
import { FrpFilterPageVisitorsDisplay } from '../../components/ferpa/FrpFilterPageVisitorsDisplay'

export const FrpPageVisitorsDisplay = () => {
    const isMobile = window.innerWidth < 768

    const [filteredVisitors, setFilteredVisitors] = useState<FrpVisitor[]>()
    const [filterCount, setFilterCount] = useState<number>(0)

    const getFrpVisitorsQuery = useGetFrpVisitorsQuery()
    const visitors = getFrpVisitorsQuery.data

    if (getFrpVisitorsQuery.isLoading)
        return <Spinner />;
    if (getFrpVisitorsQuery.isError)
        return <h3>Error getting Page Visitors</h3>;

    return (
        <div className="container" style={{marginBottom: '75px'}}>
            <h5 className="border bg-bold text-white rounded p-1">FERPA Page Visitors</h5>
            <div>
                {isMobile && 
                    <div>
                        <div className="accordion" id="filterAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#filterOptions"
                                    aria-expanded="false"
                                    aria-controls="filterOptions"
                                >
                                    Filter Options
                                    <span className="badge bg-warning rounded-pill mt-2 text-black ms-1" title="Number of filters set">
                                        {filterCount > 0 ? filterCount : ''}
                                    </span>
                                </button>
                                </h2>
                                <div id="filterOptions" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#filterAccordion" >
                                    <div className="accordion-body">
                                        <FrpFilterPageVisitorsDisplay setFilterCount={setFilterCount} setFilteredVisitors={setFilteredVisitors} visitors={visitors}/>                    
                                    </div>
                                </div>
                            </div>
                        </div>                    
                        <hr/>
                        {filteredVisitors?.map((m: FrpVisitor, index: number) => 
                            <div key={index}>
                                <TextDisplay label="Entry Date" text={FormatMonthDayAtTimeNoSeconds(m.entryDate)} />
                                <TextDisplay label="By User" text={splitUsernameFirstLast(m.userUsername)} />
                                <TextDisplay label="Page" text={m.page} />
                                <TextDisplay label="Description" text={m.description} />
                                <hr/>
                            </div>
                        )}
                    </div>
                }
                {!isMobile && 
                  <div className="table-responsive w-100">
                    <FrpFilterPageVisitorsDisplay setFilterCount={setFilterCount} setFilteredVisitors={setFilteredVisitors} visitors={visitors}/>
                    <table className="table table-striped w-sm-100 w-md-80 w-lg-50">
                            <thead>
                                <tr>
                                    <th className="me-5 pe-5">Entry Date</th>
                                    <th className="me-5 pe-5">By User</th>
                                    <th className="me-5 pe-5">Page</th>
                                    <th className="me-5 pe-5">Description</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredVisitors?.map((m: FrpVisitor, index: number) =>    
                                <tr key={index}>
                                    <td>{FormatMonthDayAtTimeNoSeconds(m.entryDate)}</td>
                                    <td>{splitUsernameFirstLast(m.userUsername)}</td>
                                    <td>{m.page}</td>
                                    <td>{m.description}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                }
                {!(visitors && visitors.length > 0) &&
                    <h5>There are not any visitors found according to the criteria entered</h5>
                }
            </div>
        </div>
    )
}

