import { useMutation, useQuery } from "@tanstack/react-query";
import { useAccessTokenHeader } from "./userHooks";
import { getQueryClient } from "../services/queryClient";
import { frpService } from "../services/frpService";
import { FrpDelegate,  FrpFacilitatorSummary,  FrpInfoType, FrpNewSession, FrpQuestion, FrpRelation, FrpSession, FrpSessionSearch, FrpStudent, FrpSummarySearch, FrpWorker } from "../models/frp";
import { useNavigate } from "react-router-dom";

const queryClient = getQueryClient();

export const frpKeys = {
  isFrpAdminKeys: ["isFrpAdminKeys"] as const,
  isFrpWorkerKeys: ["isFrpWorkerKeys"] as const,
  workerKeys: ["workerKeys"] as const,
  workersKeys: ["workersKeys"] as const,
  frpInfoTypesKey: ["frpInfoTypesKey"] as const,
  infoTypeKeys: ["infoTypeKeys"] as const,
  frpRelationsKey: ["frpRelationsKey"] as const,
  relationKeys: ["relationKeys"] as const,
  frpStudentKey: ["frpStudentKey"] as const,
  frpStudentsKey: ["frpStudentsKey"] as const,
  frpDelegatesKey: ["frpDelegatesKey"] as const,
  frpDelegateKey: ["frpDelegateKey"] as const,
  frpSessionsKey: ["frpSessionsKey"] as const,
  frpSessionKey: ["frpSessionKey"] as const,
  frpSessionsActiveKey: ["frpSessionsActiveKey"] as const,
  frpSessionActiveKey: ["frpSessionActiveKey"] as const,
  frpPageVisitorsKey: ["frpPageVisitorsKey"] as const,
  convertFerpaKey: ["convertFerpaKey"] as const,
  frpQuestionsKey: ["frpQuestionsKey"] as const,
  
};


export const useIsFrpAdminQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.isFrpAdminKeys,
    queryFn: async () => await frpService.isFrpAdmin(authHeader),
  });
};


export const useIsFrpWorkerQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.isFrpWorkerKeys,
    queryFn: async () => await frpService.isFrpWorker(authHeader),
  });
};


// Workers **********************************

export const useAddOrUpdateFrpWorkerMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpWorker
    }: {
      frpWorker: FrpWorker
    }) => {
      return frpService.addOrUpdateFrpWorker(
        frpWorker,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.workersKeys);
      },
    }
  );
};


export const useGetFrpWorkerQuery = (frpWorkerId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.workerKeys,
    queryFn: async () => await frpService.getFrpWorker(frpWorkerId, authHeader),
  });
};


export const useGetFrpWorkersQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.workersKeys,
    queryFn: async () => await frpService.getFrpWorkers(authHeader),
  });
};


export const useDeleteFrpWorkerMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpWorkerId
    }: {
      frpWorkerId: number
    }) => {
      return frpService.deleteFrpWorker(
        frpWorkerId,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.workersKeys);
      },
    }
  );
};

//********************  Info Types */

export const useGetFrpInfoTypesQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpInfoTypesKey,
    queryFn: async () => await frpService.getFrpInfoTypes(authHeader),
  });
};


export const useAddOrUpdateFrpInfoTypeMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpInfoType,
    }: {
      frpInfoType: FrpInfoType
    }) => {
      return frpService.addOrUpdateInfoType(
        frpInfoType,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpInfoTypesKey);
      },
    }
  );
};


export const useDeleteFrpInfoTypeMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpInfoTypeId
    }: {
      frpInfoTypeId: number
    }) => {
      return frpService.deleteFrpInfoType(
        frpInfoTypeId,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpInfoTypesKey);
      },
    }
  );
};


export const useGetFrpInfoTypeQuery = (frpInfoTypeId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.infoTypeKeys,
    queryFn: async () => await frpService.getFrpInfoType(frpInfoTypeId, authHeader),
  });
};

//********************  Relationships */

export const useGetFrpRelationsQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpRelationsKey,
    queryFn: async () => await frpService.getFrpRelations(authHeader),
  });
};


export const useAddOrUpdateFrpRelationMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpRelation,
    }: {
      frpRelation: FrpRelation
    }) => {
      return frpService.addOrUpdateRelation(
        frpRelation,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpRelationsKey);
      },
    }
  );
};


export const useDeleteFrpRelationMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpRelationId
    }: {
      frpRelationId: number
    }) => {
      return frpService.deleteFrpRelation(
        frpRelationId,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpRelationsKey);
      },
    }
  );
};


export const useGetFrpRelationQuery = (frpRelationId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.relationKeys,
    queryFn: async () => await frpService.getFrpRelation(frpRelationId, authHeader),
  });
};


//********************  FERPA Student */

export const useGetFrpStudentAndDelegatesQuery = (frpStudentId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpStudentKey,
    queryFn: async () => await frpService.getFrpStudentAndDelegates(frpStudentId, authHeader),
  });
};

export const useGetFrpStudentSelfQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpStudentKey,
    queryFn: async () => await frpService.getFrpStudentSelf(authHeader),
  });
};

export const useAddOrUpdateFrpStudentPassphraseMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpStudent,
    }: {
      frpStudent: FrpStudent
    }) => {
      return frpService.addOrUpdatePassphraseStudent(
        frpStudent,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpStudentKey);
      },
    }
  );
};


//********************  FERPA Delegate */

export const useGetFrpDelegateQuery = (frpDelegateId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpDelegateKey,
    queryFn: async () => await frpService.getFrpDelegate(frpDelegateId, authHeader),
  });
};

export const useGetFrpDelegatesQuery = (frpStudentId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpDelegatesKey,
    queryFn: async () => await frpService.getFrpDelegates(frpStudentId, authHeader),
  });
};

export const useGetFrpDelegateSelfQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpDelegateKey,
    queryFn: async () => await frpService.getFrpDelegateSelf(authHeader),
  });
};

export const useAddOrUpdateFrpDelegateMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpDelegate,
    }: {
      frpDelegate: FrpDelegate
    }) => {
      return frpService.addOrUpdateDelegate(
        frpDelegate,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpDelegatesKey);
      },
    }
  );
};

export const useDeleteFrpDelegateMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpDelegateId
    }: {
      frpDelegateId: number
    }) => {
      return frpService.deleteFrpDelegate(
        frpDelegateId,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpDelegatesKey);
      },
    }
  );
};


export const useAddOrUpdateFrpDirectoryInfoMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpStudent
    }: {
      frpStudent: FrpStudent
    }) => {
      return frpService.addOrUpdateFrpDirectoryInfo(
        frpStudent,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpStudentKey);
      },
    }
  );
};

//********************  FERPA Session */

export const useGetFrpSessionsActiveWorkerQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpSessionsActiveKey,
    queryFn: async () => await frpService.getFrpSessionsActiveWorker(authHeader),
  });
};

export const useSearchFrpSessionsMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      sessionSearch,
      setFilteredSessions,
      setSearching
    }: {
      sessionSearch: FrpSessionSearch,
      setFilteredSessions: (c: FrpSession[]) => void,
      setSearching: (c: boolean) => void
    }) => {
      const response = await frpService.searchFrpSessions(
        sessionSearch,
        authHeader,
      );
      return ({data: response, setFilteredSessions, setSearching})
    },
    {
      onSuccess: ({data, setFilteredSessions, setSearching}) => {
        queryClient.invalidateQueries(frpKeys.frpStudentsKey);
        setSearching(false)
        return data ? setFilteredSessions(data) : null
      },
    }
  );
};

export const useFrpFacilitatorSummaryMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      summarySearch,
      setFilteredSessions,
      setSearching
    }: {
      summarySearch: FrpSummarySearch,
      setFilteredSessions: (c: FrpFacilitatorSummary[]) => void,
      setSearching: (c: boolean) => void
    }) => {
      const response = await frpService.searchFrpFacilitatorSummary(
        summarySearch,
        authHeader,
      );
      return ({data: response, setFilteredSessions, setSearching})
    },
    {
      onSuccess: ({data, setFilteredSessions, setSearching}) => {
        queryClient.invalidateQueries(frpKeys.frpStudentsKey);
        setSearching(false)
        return data ? setFilteredSessions(data) : null
      },
    }
  );
};


export const useGetFrpSessionWorkerUsernamesQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpSessionKey,
    queryFn: async () => await frpService.getFrpSessionWorkerUsernames(authHeader),
  });
};

export const useGetFrpSessionQuery = (frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpSessionKey,
    queryFn: async () => await frpService.getFrpSession(frpSessionId,  authHeader),
  });
};

export const useAddFrpSessionMutation = () => {
  const authHeader = useAccessTokenHeader();
  const navigate = useNavigate()
  return useMutation(
    async ({
      frpNewSession
    }: {
      frpNewSession: FrpNewSession
    }) => {
      const response = await frpService.addFrpSession(frpNewSession, authHeader);
      return response
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(frpKeys.frpStudentKey);
        queryClient.invalidateQueries(frpKeys.frpSessionKey);
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        setTimeout(() => navigate(`/frp/session/active/${data}`), 500);
      },
    }
  );
};

export const useAddSessionNoFrpStudentMutation = () => {
  const authHeader = useAccessTokenHeader();
  const navigate = useNavigate()
  return useMutation(
    async ({
      student
    }: {
      student: FrpStudent
    }) => {
      const response = await frpService.addSessionNoFrpStudent(student, authHeader);
      return response
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(frpKeys.frpStudentKey);
        queryClient.invalidateQueries(frpKeys.frpSessionKey);
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        navigate(`/frp/session/active/${data.frpSessionId}`)
      },
    }
  );
};


export const useSearchFrpStudentsMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      firstNameSearch,
      lastNameSearch,
      badgeridSearch,
      usernameSearch,
      setFilteredStudents,
      setSearching
    }: {
      firstNameSearch: string,
      lastNameSearch: string,
      badgeridSearch: string,
      usernameSearch: string,
      setFilteredStudents: (c: FrpStudent[]) => void,
      setSearching: (c: boolean) => void
    }) => {
      const response = await frpService.searchFrpStudents(
        firstNameSearch,
        lastNameSearch,
        badgeridSearch,
        usernameSearch,
        authHeader,
      );
      return ({data: response, setFilteredStudents, setSearching})
    },
    {
      onSuccess: ({data, setFilteredStudents, setSearching}) => {
        queryClient.invalidateQueries(frpKeys.frpStudentsKey);
        setSearching(false)
        return data ? setFilteredStudents(data) : null
      },
    }
  );
};


export const useGetFrpSessionActiveQuery = (frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpSessionActiveKey,
    queryFn: async () => await frpService.getFrpSessionActive(frpSessionId, authHeader),
  });
};


export const useChooseSessionDelegateMutation = (frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpDelegateId
    }: {
      frpDelegateId: number
    }) => {
      return await frpService.chooseSessionDelegate(
        frpSessionId,
        frpDelegateId,
        authHeader
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};

export const useUpdateFrpSessionTalkingToMutation = (frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      talkingTo
    }: {
      talkingTo: string
    }) => {
      return await frpService.updateFrpSessionTalkingTo(
        frpSessionId,
        talkingTo,
        authHeader
      );
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        // queryClient.invalidateQueries(frpKeys.frpSessionsActiveKey);
        // queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};

export const useUpdateFrpSessionPassFailMutation = (frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      passOrFail
    }: {
      passOrFail: string
    }) => {
      return await frpService.updateFrpSessionPassFail(
        frpSessionId,
        passOrFail,
        authHeader
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};

export const useUpdateFrpSessionIsClosedMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpSessionId,
      isClosed
    }: {
      frpSessionId: number,
      isClosed: boolean
    }) => {
      return frpService.updateFrpSessionIsClosed(
        frpSessionId,
        isClosed,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};


export const useSendFrpEmailCodeMutation = (frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      emailCode
    }: {
      emailCode: string
    }) => {
      return frpService.updateFrpSessionEmailCode(
        frpSessionId,
        emailCode,
        authHeader,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};


export const useAddOrUpdateFrpCommentMutation = (frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpCommentId,
      comment_text
    }: {
      frpCommentId: number,
      comment_text: string
    }) => {
      return await frpService.addOrUpdateFrpComment(
        frpSessionId,
        frpCommentId,
        comment_text,
        authHeader
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};



export const useDeleteFrpCommentMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpCommentId,
    }: {
      frpCommentId: number,
    }) => {
      return await frpService.deleteFrpComment(
        frpCommentId,
        authHeader
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionActiveKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};


export const useGetFrpVisitorsQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.frpPageVisitorsKey,
    queryFn: async () => await frpService.getFrpVisitors(authHeader),
  });
};


export const useConvertFerpaQuery = () => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.convertFerpaKey,
    queryFn: async () => await frpService.getConvertFerpa(authHeader),
  });
};


export const useGetFerpaQuestionsQuery = (studentBadgerid: string, frpSessionId: number) => {
  const authHeader = useAccessTokenHeader();
  return useQuery({
    queryKey: frpKeys.convertFerpaKey,
    queryFn: async () => await frpService.getFerpaQuestions(studentBadgerid, frpSessionId, authHeader),
  });
};


export const useGetFerpaQuestionsMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      studentBadgerid, 
      frpSessionId,
      setQuestions 
    }: {
      studentBadgerid: string, 
      frpSessionId: number,
      setQuestions: (c: FrpQuestion[]) => void
    }) => {
      const response = await frpService.getFerpaQuestions(
        studentBadgerid, 
        frpSessionId,
        authHeader,
      );
      return ({data: response, setQuestions})
    },
    {
      onSuccess: ({data, setQuestions}) => {
        queryClient.invalidateQueries(frpKeys.frpQuestionsKey);
        return data ? setQuestions(data) : null
      },
    }
  );
};


export const useUpdateSessionLastDateMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async ({
      frpSessionId,
    }: {
      frpSessionId: number,
    }) => {
      await frpService.updateSessionLastDate(frpSessionId,authHeader);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionKey);
      },
    }
  );
};


export const useExpireOldSessionsMutation = () => {
  const authHeader = useAccessTokenHeader();
  return useMutation(
    async () => {
      await frpService.expireOldSessions(authHeader);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(frpKeys.frpSessionKey);
        queryClient.invalidateQueries(frpKeys.frpSessionsKey);
      },
    }
  );
};

