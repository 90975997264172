import { useEffect, useState } from 'react'
import { FormatShortDayOfWeekMonthDayTime } from '../../utils/dateConverter'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate } from 'react-router-dom'
import { useFrpFacilitatorSummaryMutation, useIsFrpAdminQuery, useIsFrpWorkerQuery } from '../../hooks/frpHooks'
import { FrpFacilitatorSummary, FrpSession } from '../../models/frp'
import { FrpFilterFacilitatorSummaryDisplay } from '../../components/ferpa/FrpFilterFacilitatorSummaryDisplay'

export const FrpFacilitatorSummaryList = () => {
    const navigate = useNavigate()
    const isMobile = window.innerWidth < 768

    const [filteredSessions, setFilteredSessions] = useState<FrpFacilitatorSummary[]>()
    const [filterCount, setFilterCount] = useState<number>(0)
    const [searching, setSearching] = useState<boolean>()

    const summaryQuery = useFrpFacilitatorSummaryMutation()
    const isFrpAdminQuery = useIsFrpAdminQuery()
    const isFrpAdmin = isFrpAdminQuery.data
    const isFrpWorkerQuery = useIsFrpWorkerQuery()
    const isFrpWorker = isFrpWorkerQuery.data

    useEffect(() => {
        summaryQuery.mutate({
            summarySearch: {},
            setFilteredSessions,
            setSearching
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!(filteredSessions && filteredSessions.length > 0)) <h3>No FERPA sessions found</h3>;
    if (!isFrpAdmin && !isFrpWorker) <h3>This page is reserved for FERPA administrators and facilitators</h3>

    const getSessionPassed = (sessions: FrpSession[]) => {
        const filtered = sessions && sessions?.filter(m => m.passOrFail === 'Pass')
        return String(filtered && filtered.length ? filtered.length : 0)
    }

    const getSessionFailed = (sessions: FrpSession[]) => {
        const filtered = sessions && sessions?.filter(m => m.passOrFail === 'Fail')
        return String(filtered && filtered.length ? filtered.length : 0)
    }

    const getSessionUnverified = (sessions: FrpSession[]) => {
        const filtered = sessions && sessions?.filter(m => !m.passOrFail)
        return String(filtered && filtered.length ? filtered.length : 0)
    }

    return (
        <div style={{minWidth: isMobile ? 'auto' : '1000px', marginBottom: '75px'}} className="mx-auto">
            <h5 className="border bg-bold text-white rounded p-1">Ferpa Sessions</h5>
            {isMobile && 
                <div>
                    <div className="accordion" id="filterAccordion">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#filterOptions"
                                aria-expanded="false"
                                aria-controls="filterOptions"
                            >
                                Filter Options
                                <span className="badge bg-warning rounded-pill mt-2 text-black ms-1" title="Number of filters set">
                                    {filterCount > 0 ? filterCount : ''}
                                </span>
                            </button>
                            </h2>
                            <div id="filterOptions" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#filterAccordion" >
                                <div className="accordion-body">
                                    <FrpFilterFacilitatorSummaryDisplay setFilteredSessions={setFilteredSessions} 
                                                              setFilterCount={setFilterCount} 
                                                              setSearching={setSearching}  />
                                </div>
                            </div>
                        </div>
                    </div>                    
                    {isFrpWorker && 
                        <div className="btn btn-outline-bold my-3" onClick={() => navigate("/frp/session/add")}>Add New Session</div>
                    }
                    {searching && <div className="fst-italic text-warning">Please wait while we locate records that match your criteria</div>}                    
                    <hr/>
                    {filteredSessions?.map((m: FrpFacilitatorSummary, index: number) => 
                        <div key={index}>
                            <TextDisplay label="Facilitator" text={`${m.firstName} ${m.lastName}`} />
                            <TextDisplay label="Last" text={FormatShortDayOfWeekMonthDayTime(m.lastSessionDate)} />
                            <TextDisplay label="Sessions" text={(m.sessions && m.sessions.length) || 0} />
                            <TextDisplay label="Passed" text={getSessionPassed(m.sessions)} />
                            <TextDisplay label="Failed" text={getSessionFailed(m.sessions)} />
                            <TextDisplay label="Unverified" text={getSessionUnverified(m.sessions)} />
                            <hr/>
                        </div>
                    )}
                </div>
            }
            {!isMobile && 
                <div className="mx-auto wide-container w-100" style={{width: '100%'}}>
                    <FrpFilterFacilitatorSummaryDisplay setFilteredSessions={setFilteredSessions} 
                                                setFilterCount={setFilterCount} 
                                                setSearching={setSearching}  />

                    {searching && <div className="fst-italic text-warning">Please wait while we locate records that match your criteria</div>}                    
                    <div className="table-responsive w-100">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="me-2 pe-2">Facilitator</th>
                                    <th className="me-2 pe-2">Last</th>
                                    <th className="me-2 pe-2">Sessions</th>
                                    <th className="me-2 pe-2">Passed</th>
                                    <th className="me-2 pe-2">Failed</th>
                                    <th className="me-2 pe-2">Unverified</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredSessions?.map((m: FrpFacilitatorSummary, index: number) => 
                                <tr key={index}>
                                    <td><div className="mt-2">{`${m.firstName} ${m.lastName}`}</div></td>
                                    <td><div className="mt-2">{FormatShortDayOfWeekMonthDayTime(m.lastSessionDate)}</div></td>
                                    <td><div className="mt-2">{(m.sessions && m.sessions.length) || 0}</div></td>
                                    <td><div className="mt-2">{getSessionPassed(m.sessions)}</div></td>
                                    <td><div className="mt-2">{getSessionFailed(m.sessions)}</div></td>
                                    <td><div className="mt-2">{getSessionUnverified(m.sessions)}</div></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {!(filteredSessions && filteredSessions.length > 0) &&
                <h5>There are not any FERPA sessions found according to the criteria</h5>
            }
        </div>
    )
}

