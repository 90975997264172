import { Spinner } from '../../components/ui/Spinner'
import toast from 'react-hot-toast'
import { ConfirmationToast } from '../../components/ConfirmationToast'
import { TextDisplay } from '../../components/forms/TextDisplay/TextDisplay'
import { useNavigate } from 'react-router-dom'
import { FrpWorker } from '../../models/frp'
import { useDeleteFrpWorkerMutation, useGetFrpWorkersQuery } from '../../hooks/frpHooks'
import { createInfoToastAuto } from '../../services/queryClient'

export const FrpWorkersDisplay = () => {
    const navigate = useNavigate()
    const isMobile = window.innerWidth < 768

    const deleteFrpWorker = useDeleteFrpWorkerMutation()
    const getFrpWorkersQuery = useGetFrpWorkersQuery()
    const workers = getFrpWorkersQuery.data

    if (getFrpWorkersQuery.isLoading)
        return <Spinner />;
    if (getFrpWorkersQuery.isError)
        return <h3>Error getting Facilitators</h3>;

    const sendToEditWorker = (frpWorkerId: number) => {
        if (frpWorkerId > 0) navigate(`/frp/addWorker/${frpWorkerId}`) 
    }

    const deleteConfirm = (frpWorkerId: number) => {
        toast((t) => (
            <ConfirmationToast
                toastId={t.id}
                message={`Are you sure you want to delete this facilitator?`}
                confirmHandler={() => {
                    deleteFrpWorker.mutate({frpWorkerId})
                    createInfoToastAuto(`The facilitator has been deleted.`)
                    toast.dismiss(t.id);
                }} />
        ), { duration: Infinity })
    }

    return (
        <div className="d-flex justify-content-center" style={{marginBottom: '75px'}}>
            <div style={{maxWidth: '600px', }} className="justify-content-center">
                <h5 className="border bg-bold text-white rounded p-1">FERPA Facilitators</h5>
                {workers && workers.length > 10 &&
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addWorker")}>
                        Add a Facilitator
                    </button>
                }
                <div>
                    {isMobile && 
                        <div>
                            <hr/>
                            {workers && workers.length > 0 && workers.map((m: FrpWorker, index: number) => 
                                <div key={index}>
                                    <TextDisplay label="Facilitator" text={`${m.firstName} ${m.lastName}`} />
                                    <TextDisplay label="Email address" text={m.emailAddress} />
                                    <div className="d-flex flex-nowrap mt-4">
                                        <div className="ms-4 btn btn-outline-bold" title="Edit this facilitator" onClick={() => sendToEditWorker(m.frpWorkerId || 0)}>
                                            <i className="bi bi-pencil d-block"></i>
                                        </div>
                                        <div className="ms-4 btn btn-outline-danger" title="Delete this facilitator" onClick={() => deleteConfirm(m.frpWorkerId || 0)}>
                                            <i className="bi bi-trash d-block"></i>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            )}
                        </div>
                    }
                    {!isMobile && 
                        <div className="table-responsive w-100">
                            <table className="table table-striped w-sm-100 w-md-80 w-lg-50">
                                <thead>
                                    <tr>
                                        <th className="me-3 pe-5">Facilitator</th>
                                        <th className="me-3 pe-5">Email address</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {workers && workers.length > 0 && workers.map((m: FrpWorker, index: number) => 
                                    <tr key={index}>
                                        <td>{`${m.firstName} ${m.lastName}`}</td>
                                        <td>{m.emailAddress}</td>
                                        <td>
                                            <div className="d-flex flex-nowrap">
                                                <div className="btn btn-outline-bold me-2" onClick={() => sendToEditWorker(m.frpWorkerId || 0)}>
                                                    <i className="bi bi-pencil"></i>
                                                </div>
                                                <div className="btn btn-outline-danger" onClick={() => deleteConfirm(m.frpWorkerId || 0)}>
                                                    <i className="bi bi-trash"></i>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    }
                    <button className="d-flex btn btn-outline-bold mb-4" onClick={() => navigate("/frp/addWorker")}>
                        Add a Facilitator
                    </button>
                    {!(workers && workers.length > 0) &&
                        <h5>There are not any facilitators</h5>
                    }
                </div>
            </div>
        </div>
    )
}

